import { Auth } from 'aws-amplify';


const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:71ea936d-8ee5-4faa-86f6-ff4ca76ccb10",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_dU5OROYq6",
    "aws_user_pools_web_client_id": "5qg8vmq1jtrl5hc2f66snpddeb",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "invitations-dev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "InvitationCode",
            "endpoint": "https://3b8w6xb19b.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "API",
            "endpoint": process.env.REACT_APP_API_BASE_URL,
            "region": "ap-southeast-2",    
            "custom_header": async () => { 
                return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
            }
        }
    ]
};


export default awsmobile;
