import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import * as actions from 'views/Income/actions';
import {formatMoney, formatDate, formatPercentage} from 'helpers/formating'
import {FireTable} from 'components';
import {
  Button,
  Avatar,
  Typography,
} from '@material-ui/core';

import { getInitials } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const IncomeTable = props => {
  const { className, income, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const {profile} = useSelector(state => state);
  
  const handleDelete = (selectedIncome) => {
    dispatch(actions.deleteIncome(profile, selectedIncome));
  }

  return (
    <FireTable className={className}
      rows={income}
      columns={["Name", "Amount", "From", "To", "Income Growth", "Actions"]}
      renderRow={(item) => 
        [     
          <div className={classes.nameContainer}>
            <Avatar
              className={classes.avatar}
              src={item.avatarUrl}
            >
              {getInitials(item.name)}
            </Avatar>
            <Typography variant="body1">{item.name}</Typography>
          </div>,
          formatMoney(item.amount),
          formatDate(item.startsFromUtc),
          formatDate(item.endAtUtc),
          formatPercentage(item.annualGrowthRatePercentage),
          <Button 
              component={RouterLink}
              to={`income/${item.links.self.href}`}
              variant="outlined" 
              color="primary">View</Button>,
        ]}
      onDelete={handleDelete}
  />);
};

IncomeTable.propTypes = {
  className: PropTypes.string,
  income: PropTypes.array.isRequired
};

export default IncomeTable;
