import { API } from "aws-amplify";
import * as rootActions  from 'rootActions';
import { push } from 'connected-react-router';
import { tryFetchList } from 'helpers/api';

/*
 * action types
 */

export const SELECT_LIABILITY = 'SELECT_LIABILITY';
export const LIABILITY_CREATED = 'LIABILITY_CREATED';
export const LIABILITY_DELETED = 'LIABILITY_DELETED';
export const LIABILITIES_FETCHED = 'LIABILITIES_FETCHED';


export function selectLiability(liability) {
  return { type: SELECT_LIABILITY, liability }
}

export function liabilityCreated(liability) {
    return { type: LIABILITY_CREATED, liability }
}

export function liabilityDeleted(liability) {
  return { type: LIABILITY_DELETED, liability }
}

export function liabilityList(liabilities) {
  return { type: LIABILITIES_FETCHED, liabilities }
}

export function fetchLiabilitiesList(profile) {
  return async dispatch => {
    dispatch(rootActions.loading());
    const response = await tryFetchList(profile.links.liabilities);
    dispatch(liabilityList(response));
    dispatch(rootActions.loaded());
  }
}

export function createLiability(profile, liability) {
  return async dispatch => {

    dispatch(rootActions.loading());

    try {
      const response = await API.post("API", 
        profile.links.liabilities.href,
       { body: liability } 
      );
      dispatch(liabilityCreated(response));
    } catch (err) {
      console.log(err);
    }

    dispatch(rootActions.loaded());

    dispatch(push('/liabilities'));
  }
}

async function deleteLiability(dispatch, profile, liability) {
  dispatch(rootActions.loading());

  try {
    await API.del("API", 
      liability.links.self.href
    );

    dispatch(liabilityDeleted(liability));
  } catch (err) {
    console.log(err);
  }

  dispatch(fetchLiabilitiesList(profile));

  dispatch(rootActions.loaded());
}

export function deleteLiabilities(profile, liabilities) {
  return async dispatch => {
    Promise.all(liabilities.map(l => deleteLiability(dispatch, profile, l)));
  }
}

