import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {Card, CardHeader, CardContent, TextField} from "@material-ui/core/";
import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import {MoneyInput, PercentageInput} from "components/FinancialFields";
import FrequencySelect from "components/Frequency";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  
const styles = theme => ({
    personDetails : {
        '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
        },
    }
});

const StyledTextField = (props) => 
    <TextField 
        variant="outlined" 
        margin="normal" 
        required 
        {...props}/>

const peopleCount = (state) => {
  if (state.familyStatus == "Individual")
    return 1;
  if (state.familyStatus == "Couple") 
    return 2;
  if(state.familyStatus == "Family")
   return 3;
}

const PersonDetails = ({className, classes, state, handleChange}) => {
    const textProps = {variant:"outlined", margin:"normal", required: true}
    return (
      <Card className={className}>
        <CardHeader title="Person 1"/>
        <CardContent className={classes.personDetails}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
            <div>
              <StyledTextField
                name="given_name"
                label="First name"
                value={state.firstName}
                onChange={handleChange("firstName")}
              />
              <StyledTextField
                name="family_name"
                label="Last name"
                value={state["lastName"]}
                onChange={handleChange("lastName")}
              />
              <KeyboardDatePicker
                {...textProps}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Birthday"
                value={state["birthday"]}
                onChange={handleChange("birthday")}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                />
            </div>
            <div>
              <StyledTextField
                  fullWidth 
                  name="occupation"
                  label="Occupation"
                  value={state["occupation"]}
                  onChange={handleChange("occupation")}
                />
            </div>
            <div>
                <MoneyInput
                  {...textProps}
                  id="income"
                  name="income"
                  label="Income"
                  value={state["income"]}
                  onChange={handleChange("income")}
                />
                <MoneyInput
                  {...textProps}
                  id="super_balance"
                  name="super_balance"
                  label="Super balance"
                  value={state["superBalance"]}
                  onChange={handleChange("superBalance")}
                />
                 <StyledTextField
                  id="retirement_age"
                  name="retirement_age"
                  label="Retirement age"
                  type="number"
                  value={state["retirementAge"]}
                  onChange={handleChange("retirementAge")}
                />
            </div>
          </MuiPickersUtilsProvider>
        </CardContent>
      </Card>);
  }

  export default withStyles(styles)(PersonDetails);