import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

const NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: parseInt(values.value),
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  }
  
  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  const MoneyInput = ({value, onChange, ...rest}) => {
    const inputEl = React.useRef(null);
    const onChangeHadler = (e) => {
      const {name, validity, form} = inputEl.current;
      const {value} = e.target;

      onChange({
        ...e,
        currentTarget: {
          name,
          value,
          validity,
          form,
        }
      });
    };

    return <TextField
        {...rest}
        value={value}
        onChange={onChangeHadler}
        InputProps={{
          inputComponent: NumberFormatCustom,
          inputRef: inputEl,
        }}
    />
  }

  export default MoneyInput;
  