import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AssetsList from './AssetsList';
import { styles } from 'dashboardStyles';
import Skeleton from '@material-ui/lab/Skeleton';
import {fetchAssetsList} from 'views/Assets/actions';

const Assets = () => {
    const {profile, app, assets} = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        if (profile.links === undefined)
          return;

          dispatch(fetchAssetsList(profile));
    }, [profile.id]);

    const Loading = () => (
      <div>    
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
      </div>);

     return <Grid item xs={12}>
      {app.isLoading ? <Loading/> : <AssetsList  assets={assets.list} />}
     </Grid>;
}
export default Assets