import { LIABILITIES_FETCHED, SELECT_LIABILITY, LIABILITY_CREATED, LIABILITY_DELETED } from './actions'

const initialState = {
    editor: {},
    list: []
}

function selectLiability(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState =  Object.assign({}, state, { editor: action.liability });
  return newState
}

function selectLiabilitiesList(state = initialState, action) {
    var newState = Object.assign({}, state);
    newState =  Object.assign({}, state, { list: action.liabilities });
    return newState
}

function reducer(state = initialState, action){
    switch (action.type) {
        case SELECT_LIABILITY:
            return selectLiability(state, action);
        case LIABILITY_CREATED:
            return selectLiability(state, action);
        case LIABILITY_DELETED:
            return selectLiability(state, action);
        case LIABILITIES_FETCHED:
            return selectLiabilitiesList(state, action);
    default:
        return state;
    }
}
export {reducer, initialState};