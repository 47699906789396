import { API } from "aws-amplify";
import * as rootActions  from 'rootActions';
import { push } from 'connected-react-router';
import { tryFetchList } from 'helpers/api';

/*
 * action types
 */

export const SELECT_EXPENSE = 'SELECT_EXPENSE'
export const EXPENSE_CREATED = 'EXPENSE_CREATED'
export const EXPENSES_FETCHED = 'EXPENSES_FETCED';
export const EXPENSE_DELETED = 'EXPENSE_DELETED';

export function selectExpense(expense) {
  return { type: SELECT_EXPENSE, expense }
}

export function expenseCreated(expense) {
    return { type: EXPENSE_CREATED, expense }
}

export function expensesList(expenseList) {
  return {type: EXPENSES_FETCHED, expenseList}
}


export function expenseDeleted(expense) {
  return {type: EXPENSE_DELETED, expense}
}

export function createExpense(profile, expense) {
  return async dispatch => {

    dispatch(rootActions.loading());

    try {
      const href = profile.links.expenses.href;
      const expenseResponse = await API.post("API", href, { body: expense } );
      dispatch(expenseCreated(expenseResponse));
    } catch (err) {
      console.log(err);
    }

    dispatch(rootActions.loaded());

    dispatch(push('/expenses'));
  }
}

export function fetchExpenseList(profile) {
  return async dispatch => {
    dispatch(rootActions.loading());
    const expenses = await tryFetchList(profile.links.expenses);
    dispatch(expensesList(expenses));
    dispatch(rootActions.loaded());
  }
}

async function deleteAction(dispatch, profile, expense) {
  dispatch(rootActions.loading());

  try {
    await API.del("API", expense.links.self.href);

    dispatch(expenseDeleted(expense));
  } catch (err) {
    console.log(err);
  }

  dispatch(fetchExpenseList(profile));

  dispatch(rootActions.loaded());
}

export function deleteExpense(profile, expense) {
  return async dispatch => {
    Promise.all(expense.map(i => deleteAction(dispatch, profile, i)));
  }
}
