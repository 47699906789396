import React from 'react';

export const wizardStep = (Component) => ({isActive, ...props}) => {
    if (!isActive) {
        return null;
    }

    // TODO: wrap in a div and set display none instead? test hooks
    return (
        <Component {...props} />
    );
};
