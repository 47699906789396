import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import FrequencySelect from "components/Frequency";
import { PercentageInput, MoneyInput } from "components/FinancialFields";
import { mutate } from "helpers/mutations";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.divider}`,
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
  content: {
    overflowY: "hidden",
  },
}));

const LiabilityForm = ({ open, onClose, onSave, state, onChange }) => {
  const classes = useStyles();

  if (!open || state === undefined) {
    return null;
  }

  const parseInput = (target) => {
    return target.type == "number" ? parseInt(target.value) : target.value;
  };

  const value = (event) => {
    const { target } = event;
    return target !== undefined ? parseInput(target) : event;
  };

  const handleChange = (name) => (event) => {
    onChange(mutate(state, { [name]: value(event) }));
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle>Liability</DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>
          Changes made to the "Name" and "Amount" may affect other scenarios
        </DialogContentText>

        <Grid container spacing={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                id="name"
                label="Name"
                value={state["name"]}
                onChange={handleChange("name")}
                type="text"
                variant="filled"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MoneyInput
                id="value"
                label="Value"
                value={state["value"]}
                onChange={handleChange("value")}
                type="text"
                variant="filled"
                fullWidth
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                disabled
                label="Loan term"
                value={state.loanTerm}
                type="number"
                variant="filled"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                disabled
                label="Interest Only Period"
                value={state.interestOnlyPeriod}
                type="number"
                variant="filled"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PercentageInput
                variant="filled"
                name="interestRate"
                label="Interest Rate"
                value={state["interestRate"]}
                onChange={handleChange("interestRate")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeyboardDatePicker
                showTodayButton
                inputVariant="filled"
                id="date-picker-inline"
                openTo="year"
                views={["year", "month"]}
                label="Acquire"
                value={state["startsFromUtc"]}
                onChange={handleChange("startsFromUtc")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeyboardDatePicker
                inputVariant="filled"
                id="date-picker-inline"
                openTo="year"
                views={["year", "month"]}
                label="Discharge"
                value={state["endsAtUtc"]}
                onChange={handleChange("endsAtUtc")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MoneyInput
                variant="filled"
                name="extraRepayment"
                label="Extra repayments"
                value={state["extraRepayment"]}
                onChange={handleChange("extraRepayment")}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FrequencySelect
                value={state.frequency}
                onChange={handleChange("frequency")}
                fullWidth
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSave(state)} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LiabilityForm;

