import React, { useEffect } from "react";
import {useLocation, useParams} from "react-router-dom";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import {
  Overview,
  CashflowProjection,
  ScenarioConfiguration,
} from "./components";
import { DataContext } from "./CashflowContext";
import { useDispatch, useSelector} from "react-redux";
import { fetchScenarioForecast } from './actions';

const useScenarioUri = () => {
  const {scenarioUri, scenarioId} = useParams();
  const location = useLocation();

  if (scenarioUri)
    return scenarioUri;

  if ({scenarioId}) {
    return location.pathname.replace('/scenarios/', '');
  }

  return '';
}

const Dashboard = (props) => {
  const {forecast, profile} = useSelector(state => ({ 
    forecast:  state.scenarios.forecast, 
    scenario: state.scenarios.editor, 
    profile: state.profile
  }));

  const dispatch = useDispatch();
  const scenarioUri = useScenarioUri();
  useEffect(() => {
    dispatch(fetchScenarioForecast(`/${scenarioUri}`, profile));
  }, [scenarioUri]);

  const { classes } = props;

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <DataContext data={forecast} select={x => x.equity}>
      <Grid item xs={12}>
        <Overview/>
      </Grid>
      <Grid item xs={12}>
        <CashflowProjection className={fixedHeightPaper} data={forecast} />
      </Grid>
      <Grid item xs={12}>
        <ScenarioConfiguration />
      </Grid>
    </DataContext>
  );
}

export default Dashboard;
