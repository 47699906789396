import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, TextField } from "@material-ui/core/";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import FrequencySelect from "components/Frequency";
import { PercentageInput, MoneyInput } from 'components/FinancialFields';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const styles = theme => ({
  details: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200
    }
  }
});


const ExpenseDetails = ({ className, classes, state, onChange }) => {
  const textProps = {variant:"outlined", margin:"normal", required: true}
  return (
    <Card className={className}>
      <CardHeader title="New expense details" />
      <CardContent className={classes.details}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
          <div>
            <TextField
             {...textProps}
              name="name"
              label="Name"
              type="text"
              value={state["name"]}
              onChange={onChange("name")}
            />
            <MoneyInput
              {...textProps}
              name="value"
              label="Amount"
              value={state["amount"]}
              onChange={onChange("amount")}
            />
            <FrequencySelect
              value={state.frequency}
              onChange={onChange("frequency")}
            />
          </div>
          <div>
            <KeyboardDatePicker
              {...textProps}
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Expense begins"
              value={state["startsFromUtc"]}
              onChange={onChange("startsFromUtc")}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
            <KeyboardDatePicker
              {...textProps}
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Expense ends"
              value={state["endAtUtc"]}
              onChange={onChange("endAtUtc")}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
            <PercentageInput
              id="expense"
              {...textProps}
              name="expense"
              label="Annual inflation"
              value={state["expectedAnnualInflationRate"]}
              onChange={onChange("expectedAnnualInflationRate")}
            />
          </div>
        </MuiPickersUtilsProvider>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ExpenseDetails);
