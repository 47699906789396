import React, { Component, Fragment } from 'react';
import { Button, TextField, Box } from '@material-ui/core';
import { ForgotPassword } from 'aws-amplify-react'
import Auth from '@aws-amplify/auth';
import AuthComponent from './AuthComponent'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid';

class FireForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this.sendView = this.sendView.bind(this);
    this.submitView = this.submitView.bind(this);
    this.gotoSignIn = this.gotoSignIn.bind(this);
    this.load = this.load.bind(this); 
    
    this.state = this.initialState = {
      loading: false,
      delivery: null
    }
  }

  send() {   
    const { authData={} } = this.props;
    const username = this.getUsernameFromInput() || authData.username;

    return Auth.forgotPassword(username)
        .then(data => {
            this.setState({ delivery: data.CodeDeliveryDetails });
        })
        .catch(err => this.error(err));
  }

  submit() {
    const { authData={} } = this.props;
    const { code, password } = this.inputs;
    const username = this.getUsernameFromInput() || authData.username;

    return Auth.forgotPasswordSubmit(username, code, password)
        .then(data => {
            this.changeState('signIn');
            this.setState({ delivery: null });
        })
        .catch(err => this.error(err));
}

  load(func){
    return async () => {
      this.setState({loading : true});
      await func()
      this.setState({loading : false});
    }
  }
    
  gotoSignIn() {
    this.setState(this.initialState);
    // to switch the authState to 'signIn'
    this.props.onStateChange('signIn',{});
  }
  sendView(classes) {
    return (
      <Fragment>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          key="email"
          onChange={this.handleInputChange}      
          autoComplete="username email"
          autoFocus
        />
        <Button
          size="large"
          fullWidth
          color="primary"
          margin="normal"
          variant="contained"
          onClick={this.load(this.send)}
          className={ classes.submit}>
          Send code
        </Button>
    </Fragment> 
    );
}

submitView(classes) {
    return (
      <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <TextField variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="code"
                    label="Confirmation Code"
                    type="tel"
                    id="code"
                    autoFocus
                    onChange={this.handleInputChange} />  
        </Grid>
        <input readOnly type="email" style={{display: 'none'}}  name="email" autoComplete="email" id="username" value={this.inputs.email}></input>
 
        <Grid item xs={12} >
            <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="New Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    onChange={this.handleInputChange}/>
          </Grid>
          <Grid item xs={12} >
              <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="confirm_password"
                    label="Confirm Password"
                    type="password"
                    id="confirm_password"
                    autoComplete="new-password"
                />
          </Grid>
        </Grid>
        <Button
          size="large"
          color="primary"
          fullWidth
          margin="normal"
          variant="contained"
          onClick={this.load(this.submit)}
          className={ classes.submit}>
          Change password
        </Button>
      </Fragment>
    );
}

  showComponent(theme){
    const { authState, hide, authData={} } = this.props;

    return (
      <AuthComponent title="Forgot password" loading={this.state.loading} {...this.props}
        component={({ classes }) =>
        <div className={classes.form} >
         {this.state.delivery || authData.username ? this.submitView(classes) : this.sendView(classes)}
         <Grid container justify="flex-start">
            <Grid item>
              <Link href="#" onClick={this.gotoSignIn} variant="body2">
                Go back to sign in?
            </Link>
            </Grid>
          </Grid> 
        </div>
        }/>
    )
  }

};

export default FireForgotPassword;