import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Amplify, { API } from 'aws-amplify';

class RegisterYourInterest extends React.Component {
  constructor(props) {
    super(props);
    this.registerInterest = this.registerInterest.bind(this);
  }

  state = {
      emailAddress: ''
  };

  async registerInterest(){
    let data = { 
        response: true,
        body: this.state
    }
  
    const resp = await API.post("InvitationCode", "/invitations/requests", data)
    .catch(error => {
        console.log(error.response);
    });

    if(resp && resp.status == 200) {
      this.setState({requestSent: true});
      console.log(resp);
    }
  }

  render() {
    return (
        <Fragment>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              key="email"
              value={this.state.emailAddress}
              autoComplete="email"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={this.state.isLoading}
              onClick={this.registerInterest}>
              Request Invitation
            </Button>
        </Fragment>
      )
  };
}

export default RegisterYourInterest;