import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { compose } from "redux";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Typography, LinearProgress } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import {SignOut} from 'views';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  }
}));

const Topbar = props => {
  const { className, onToggleSidebar, onToggleProfileSwitcher, ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);

  return (
    <AppBar
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <Hidden>
          <IconButton
            color="inherit"
            onClick={onToggleSidebar}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <img
            alt="Better Plan"
            src="/images/logos/better-plan-white.svg"
          />
        </RouterLink>
        
      {/* <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          Fire Planner
      </Typography> */}
        <div className={classes.flexGrow} />
          <IconButton color="inherit"
            onClick={onToggleProfileSwitcher}>
            <Badge
              color="primary"
              variant="dot">
              <PeopleOutlineIcon />
            </Badge>
          </IconButton>
          
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <SignOut className={classes.signOutButton} color="inherit"/>
        
      </Toolbar>
      
      { props.isLoading && (
        <LinearProgress />
      )}
    </AppBar>
  );
};

const mapStateToProps = state => {
  return Object.assign({}, state.app);
};

const enhance = compose(
  connect(mapStateToProps),
);

Topbar.propTypes = {
  className: PropTypes.string,
  onToggleSidebar: PropTypes.func,
  onToggleProfileSwitcher: PropTypes.func
};

export default enhance(Topbar);