import React, { Fragment, useState, useEffect } from 'react';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from 'react-redux';
import {fetchScenarioCashAccounts} from '../../actions';

const CashAccountProvider = ({render}) => {
    const dispatch = useDispatch();

    const {scenario, scenarioCashAccounts} = useSelector(state => ({
      scenario: state.scenarios.editor,
      scenarioCashAccounts: state.scenarios.scenarioCashAccounts
    }));
  
    useEffect(() => {
      if (!scenarioCashAccounts.length)
        return;
  
      dispatch(fetchScenarioCashAccounts(scenario));
    }, [scenario]);
  
    return render({scenarioCashAccounts})
  }
  
  const CashAccountSelector = ({className, value, onChange}) => {
    return <CashAccountProvider
    render={({scenarioCashAccounts}) =>
      <TextField
        className={className}
        id="select-cashAccount"
        select
        label="Linked account"
        value={value}
        onChange={onChange}
        helperText="The cash account to make deposits and withdrawals"
        variant="filled">
      {scenarioCashAccounts.map(c => <MenuItem value="Daily">{c.name}</MenuItem>)}
    </TextField>
    }
    />
  }

  export {CashAccountSelector};