import { API } from "aws-amplify"

export const LOADING = 'LOADING'
export const LOADED = "LOADED"
export const API_ROOT_FETCHED = "API_ROOT_FETCHED;"
export const ACCOUNT_FETCHED = "ACCOUNT_FETCHED;"

/*
 * action creators
 */

export function loading() {
  return { type: LOADING }
}

export function loaded() {
    return { type: LOADED }
}

export function fetchApiRoot() {
  return async dispatch => {
    const root = await API.get("API", "/")
    dispatch({ type: API_ROOT_FETCHED, root })
  }
}