import React, { Fragment, useCallback, useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import { updateScenario } from 'views/Scenarios/actions';
import {mutate} from 'helpers/mutations';
import { TextField, Grid, Typography, LinearProgress } from "@material-ui/core";
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import { makeStyles } from "@material-ui/core/styles";
import { FireCard } from "components";
import MoneyIcon from '@material-ui/icons/Money';
import PeopleIcon from '@material-ui/icons/People';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { formatMoney } from "helpers/formating";
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  primaryBackground: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  whiteBackground: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  
  progress: {
    marginTop: theme.spacing(3)
  },

  retirementAge: {
    all: "inherit",
    width: "70px",
   "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button" : {  
      opacity: 1
    }   
  }
}));

const Description = ({text, classes}) =>{
  return <Typography
        className={classes.caption}
        variant="caption">
        {text}
      </Typography>
}

const IncreaseText = ({changeInValue, description, classes}) => {
  return <Fragment>
            <ArrowUpwardIcon className={classes.differenceIcon} />
            <Typography
              className={classes.differenceValue}
              variant="body2"
            >
              {changeInValue}
            </Typography>
          <Description text={description} classes={classes}/>
        </Fragment>
}

const useDebounce = (action, delay) => useCallback(_.debounce(action, delay), []);

const RetirementAge =  ({classes, retirmentAge, yearsRemaining, onChange}) => {

  const [value, setValue] = useState(retirmentAge);

  const throttle = useDebounce(onChange, 200);

  const handleChange = ({target}) => {
    const age = +target.value;
    setValue(age);
    throttle(age);
  }

  return <FireCard
    title="RETIREMENT AGE" 
    value={<input className={classes.retirementAge} type="number" value={value} onChange={handleChange}/>} 
    icon={PeopleIcon}>
      <div className={classes.difference}>
        <Description 
          text={`${yearsRemaining} years until retirement`} 
          classes={classes} />
      </div>
  </FireCard>;
};

const Overview = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {forecast, scenario, profile, primaryPerson} = useSelector(state => ({ 
    forecast:  state.scenarios.forecast, 
    scenario: state.scenarios.editor, 
    profile: state.profile,
    primaryPerson: state.people.find(x => x.isPrimary)
  }));

  const handleRetirementAgeChange = (retirementAge) => {
    dispatch(updateScenario(mutate(scenario, {retirementAge})));
  }

  return (
    <Grid container spacing={2}>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <RetirementAge 
          classes={classes}
          retirmentAge={scenario.retirementAge}
          yearsRemaining={forecast.yearsUntilRetirement}
          onChange={handleRetirementAgeChange}
        />
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <FireCard 
            title="ESTIMATED NETWORTH" 
            value={formatMoney(forecast.retirementNetworth)}
            icon={MoneyIcon}>
          <div className={classes.difference}>
            <IncreaseText
              changeInValue="3400%" 
              description="Increase by retirement" 
              classes={classes} />
          </div>
        </FireCard>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <FireCard 
            title="FI PROGRESS" 
            value="25.5%" 
            iconColor={classes.primaryBackground}
            icon={InsertChartIcon}>
          <LinearProgress
            className={classes.progress}
            value={25.5}
            variant="determinate"/>
        </FireCard>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <FireCard 
          className={classes.primaryBackground}
          title="RETIREMENT INCOME" 
          value={formatMoney(forecast.retirementIncome)}
          textColor="inherit"
          iconColor={classes.whiteBackground}
          icon={AttachMoneyIcon}>
        </FireCard>
      </Grid>
    </Grid>
  );
};

export default Overview;
