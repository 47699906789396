import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {Card, CardHeader, CardContent, TextField} from "@material-ui/core/";
import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import {MoneyInput, PercentageInput} from "components/FinancialFields";
import FrequencySelect from "components/Frequency";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  
const styles = theme => ({
    personDetails : {
        '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
        },
    }
});

const StyledTextField = (props) => 
    <TextField 
        variant="outlined" 
        margin="normal" 
        required 
        {...props}/>

const AssetDetails = ({className, classes, state, handleChange}) => {
  const textProps = {variant:"outlined", margin:"normal", required: true}

    return (
      <Card className={className}>
        <CardHeader title="Details"/>
        <CardContent className={classes.personDetails}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
            <div>
              
            <KeyboardDatePicker
                {...textProps}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Purchase date"
                value={state["valuedAt"]}
                onChange={handleChange("valuedAt")}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                />
              <MoneyInput
                {...textProps}
                name="value"
                label="Current value"
                value={state["value"]}
                onChange={handleChange("value")}
              />
               <PercentageInput
                {...textProps}               
                  id="income"
                  name="income"
                  label="Annual growth rate"
                  value={state["annualGrowthRatePercentage"]}
                  onChange={handleChange("annualGrowthRatePercentage")}
                />
                <PercentageInput
                {...textProps}
                  id="income"
                  name="income"
                  label="Annual growth rate"
                  value={state["annualGrowthRatePercentage"]}
                  onChange={handleChange("annualGrowthRatePercentage")}
                />
                <PercentageInput
                {...textProps}
                  id="income"
                  name="income"
                  label="Annual income percentage"
                  value={state["annualIncomePercentage"]}
                  onChange={handleChange("annualIncomePercentage")}
                />
                <PercentageInput
                  {...textProps}
                  id="expenses"
                  fullWidth
                  name="Annual expense percentage"
                  label="Annual expense percentage"
                  value={state["annualExpensePercentage"]}
                  onChange={handleChange("annualExpensePercentage")}
                />
            </div>
          </MuiPickersUtilsProvider>
        </CardContent>
      </Card>);
  }

  export default withStyles(styles)(AssetDetails);