import React from 'react';
import {KeyboardDatePicker} from "@material-ui/pickers";

const DatePicker = ({onChange, ...rest}) => {
    const inputEl = React.useRef(null);

    const onChangeHandler = (date) => {
        const {name, validity, form} = inputEl.current;
        const {valid} = validity;
        const isValidDate = date instanceof Date && !isNaN(date) && date !== null;

        onChange({
            currentTarget: {
                name,
                value: date,
                validity: {...validity, valid: valid && isValidDate}, 
                form,
            }
        });
    };

    return (
        <KeyboardDatePicker 
            {...rest}
            inputRef={inputEl}
            format="dd/MM/yyyy"
            onChange={onChangeHandler}
            KeyboardButtonProps={{
                "aria-label": "change date"
            }}
        />
    );
};

export {DatePicker};
