import React, {Suspense, lazy} from 'react';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { styles } from 'dashboardStyles';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { RouteWithLayout } from 'components';
import { Main as MainLayout, Minimal as MinimalLayout } from 'layouts';
import Loader from 'react-loader-spinner';

const styling = withStyles(styles);

const Fallback = () => <MainLayout open={true}>
  <Box display="flex" justifyContent="center" alignItems="center">
    <Loader
       type="Audio" 
       color="Grey"
       timeout={3000} //3 secs
       width={2000}
       height={500}
	  /></Box>
</MainLayout>;

const Routes = () => {
  return (
    <Suspense fallback={<Fallback/>}>
      <Switch>
        <RouteWithLayout
          layout={MainLayout}
          exact
          path="/"  component={styling(lazy(() => import('./lazy/Overview')))} 
        />      
        <RouteWithLayout
          layout={MainLayout}
          path="/onboarding"  component={styling(lazy(() => import('./lazy/Onboarding')))} 
        />
        <RouteWithLayout
          exact
          layout={MainLayout}
          path="/income" component={lazy(() => import('./lazy/IncomeList'))}
        />
        <RouteWithLayout
          layout={MainLayout}
          path="/income/create" component={lazy(() => import('./lazy/CreateIncome'))}
        />
        <RouteWithLayout
          exact
          layout={MainLayout}
          path="/expenses" component={lazy(() => import('./lazy/ExpenseList'))}
        />
        <RouteWithLayout
          layout={MainLayout}
          path="/expenses/create" component={lazy(() => import('./lazy/CreateExpense'))}
        />
        <RouteWithLayout
          exact
          layout={MainLayout}
          path="/assets" exact component={lazy(() => import('./lazy/AssetList'))} 
        />
        <RouteWithLayout
          exact
          layout={MainLayout}
          path="/assets/create" component={lazy(() => import('./lazy/CreateAsset'))}
        />
        <RouteWithLayout
          exact
          layout={MainLayout}
          path="/liabilities" component={styling(lazy(() => import('./lazy/LiabilitiesList')))}
        />
        <RouteWithLayout
          exact
          layout={MainLayout}
          path="/liabilities/create" component={lazy(() => import('./lazy/CreateLiability'))}
        />
        <RouteWithLayout
          layout={MainLayout}
          path="/liabilities/:id/view" component={styling(lazy(() => import('./lazy/Amortisation')))}/> 

        <RouteWithLayout
          exact
          layout={MainLayout}
          path="/scenarios" component={lazy(() => import('./lazy/ScenarioList'))}
        />
        
        <RouteWithLayout
          layout={MainLayout}
          exact
          path="/profiles/create"  component={lazy(() => import('./lazy/CreateProfile'))}
        />
        <RouteWithLayout
          layout={MainLayout}
          exact
          path="/scenarios/create"  component={lazy(() => import('./lazy/CreateScenario'))}
        />
        <RouteWithLayout
          layout={MainLayout}
          exact
          path="/scenarios/:scenarioUri"  component={styling(lazy(() => import('./lazy/Scenarios')))} 
        />
        <RouteWithLayout
          exact
          layout={MainLayout}
          path="/scenarios/profiles/:profileId/scenarios/:scenarioId" component={styling(lazy(() => import('./lazy/Scenarios')))}
        />

        <RouteWithLayout
          layout={MainLayout}
          path="/api-tester" component={styling(lazy(() => import('./lazy/ApiTester')))}
        />
        <RouteWithLayout
          layout={MainLayout}
          path="/welcome" component={lazy(() => import('./lazy/Welcome'))}
        />
        <RouteWithLayout
          component={lazy(() => import('./lazy/NotFound'))}
          exact
          layout={MainLayout}
          path="/not-found"
        />
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  );
};

export default withRouter(Routes);
