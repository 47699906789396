import React, { useState, useEffect } from 'react';
import { API } from "aws-amplify"
import { useSelector } from 'react-redux';

const AccountProvider = ({history, children}) => {
    const accountsEndpoint = useSelector(state => state.api.links.accounts.href);
    const [hasAccount, setHasAccount] = useState();
    useEffect(() => {
        const getAccount = async () => {
            try {
                const accountsResponse = await API.get("API", accountsEndpoint);
                setHasAccount(true);
            } catch (err) {
                if (err.response.status === 404) {
                    setHasAccount(false);
                } else {
                    throw err;
                };
            }
        };

        getAccount();
    }, []);

    if (hasAccount === undefined) {
        return null;
    }

    if (!hasAccount) {
        history.push("/welcome");
    }

    return children;
}

export { AccountProvider };
