import React, { useReducer } from "react";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {Grid} from "@material-ui/core/";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { push } from "connected-react-router";
import * as actions from "../../actions";
import ProfileDetails from "./ProfileDetails";
import PersonDetails from "./PersonDetails";
import {mutate} from 'helpers/mutations';

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  card: {
    marginTop: theme.spacing(4),
  },
  buttons: {
    marginTop: theme.spacing(3)
  }
});

function reducer(state, {name, value}){
  return {
    ...state,
    [name]: value
  }
}

const reduceMany = (state, action) => {
    const copy = [...state];
    copy[action.index] = mutate(copy[action.index], {[action.name]: action.value});
    return copy;
}

const CreateProfile = props => {

  const profilesEndpoint = useSelector(state => state.api.links.profiles.href);
  
  const [profile, setProfile] = useReducer(reducer, {
      name: "",
      familyStatus: "Individual",
    });
    
  const [people, setPeople] = useReducer(reduceMany, [{
      isPrimary: true
    }]
  );

  const parseInput = (target) => {
    return target.type == "number" ? parseInt(target.value) : target.value;
  }

  const value = event  => {
    const { target } = event;
    return target !== undefined ? parseInput(target) : event;
  } 
  
  const handleProfileChange = name => event => {
    setProfile({ name, value: value(event) });
  };

  const handlePeopleChange = index => name => event => {
    setPeople({ index: index, name: name, value: value(event)});
  }

  const { classes } = props;

  return (
    <Grid item xs={12}>
      <ProfileDetails className={classes.card} handleChange={handleProfileChange} state={profile} />
      {people.map((person,index) => 
          <PersonDetails key={index} className={classes.card} handleChange={handlePeopleChange(index)} state={person}/>
      )};
      <div className={classes.buttons} style={{ flex: 1 }}>
        <Button variant="contained" color={ props.app.isLoading ? "default" : "primary" }  
          onClick={() => props.createProfile(profilesEndpoint, profile, people)}
          className={classes.button}>
          Create profile
        </Button>
      </div>
    </Grid>
  );
};

const mapStateToProps = state => {
  return Object.assign({}, { app: state.app, profile: state.profile });
};

const mapDispatchToProps = {
  ...actions,
  push
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
);

export default enhance(CreateProfile);
