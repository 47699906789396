import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link'
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { SignIn } from 'aws-amplify-react'
import AuthComponent from './AuthComponent'


class FireSignIn extends SignIn {
  constructor(props) {
    super(props);
    this.gotoSignUp = this.gotoSignUp.bind(this);
    this.signIn = this.signIn.bind(this);

    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }

  state = { 
    loading : false
  }

  gotoSignUp() {
    // to switch the authState to 'signIn'
    this.changeState("signUp")
  }

  signIn() {
    this.setState({loading : true});
    super.signIn();
    this.setState({unlocked : true});
  }


  showComponent(theme) {
    return (
      <AuthComponent title="Sign In" loading={this.state.loading} unlocked={this.state.unlocked} {...this.props} 
        component={({ classes }) =>
          <div className={classes.form} >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              key="email"
              onChange={this.handleInputChange}
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={this.handleInputChange}
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={this.state.isLoading}
              className={classes.submit}
              onClick={this.signIn}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2"  onClick={() => this.changeState('forgotPassword')}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" onClick={this.gotoSignUp} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </div>
        }/>
      )
  };
}

export default FireSignIn;