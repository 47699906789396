import { API } from "aws-amplify";
import * as rootActions  from 'rootActions'

/*
 * action types
 */

export const FINANCIAL_SUMMARY_FETCHED = "FINANCIAL_SUMMARY_FETCHED"

export function financialSummaryFetched(financialSummary) {
  return { type: FINANCIAL_SUMMARY_FETCHED, financialSummary }
}

export function fetchFinancialSummary(profile) {
  return async dispatch => {
    dispatch(rootActions.loading());
    try {
      const financalSummary = await API.get("API", profile.links.financialSummary.href);
  
      dispatch(financialSummaryFetched(financalSummary));
  
    } catch (err) {
      console.log(err);
    }

    dispatch(rootActions.loaded());
  }
}
