import React, {Fragment, cloneElement} from "react";
import { makeStyles } from "@material-ui/styles";

import { Button, Menu } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(1),
    }
}));

const DropdownMenu = ({
  title,
  icon,
  onOpen = () => {},
  onClose = () => {},
  children,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    onOpen();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (view) => {
    onClose();
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <Fragment>
      <Button size="small" variant="text" onClick={handleOpen}>
        {icon ? cloneElement(icon, { className: classes.icon, size: "small" }) : null} {title} {icon ? null : <ArrowDropDownIcon/> }
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </Fragment>
  );
};

export default DropdownMenu;
