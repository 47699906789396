import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector} from "react-redux";
import { makeStyles } from '@material-ui/styles';
import {  MultiSelectTable } from 'components';
import {formatMoney, formatDate, formatPercentage} from 'helpers/formating'
import { getInitials } from 'helpers';
import CashAccountForm from './CashAccountForm';
import {updateCashAccount} from '../../actions'
import EditLink from '../Editing/EditLink';

import {
  Avatar,
  Typography,
} from '@material-ui/core';
import { addCashAccount, removeCashAccount } from 'views/Scenarios/components/Dashboard/actions';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const ScenarioCashAccounts = () => {

  const {scenario, scenarioCashAccounts, profileCashAccounts, profile} = useSelector(state => ({
    scenario: state.scenarios.editor, 
    scenarioCashAccounts: state.scenarios.scenarioCashAccounts,
    profileCashAccounts: state.scenarios.profileCashAccounts,
    profile: state.profile
  }));


  const uris = new Set(scenarioCashAccounts.map(x => x.links.cashAccount.href));
  const profileOnlyCashAccounts = profileCashAccounts.filter(x => !uris.has(x.links.self.href));

  const classes = useStyles();

  const dispatch = useDispatch();

  const [openForm, setOpenForm] = useState(false);
  const [editingItem, setEditing] = useState(undefined);

  const handleEdit = (item) => {
    setEditing(item);
    setOpenForm(true);
  };

  const handleSave = (item) => {
    dispatch(updateCashAccount(profile, scenario, item))
    setOpenForm(false);
  };

  const handleChange = item => {
    setEditing(item);
  }

  return (
    <Fragment>
      <MultiSelectTable
    rows={[...scenarioCashAccounts, ...profileOnlyCashAccounts]}
    columns={["Name", "Balance", "Cash rate", "Actions" ]}
    renderRow={(item) => 
      [     
        <div className={classes.nameContainer}>
          <Avatar
            className={classes.avatar}
            src={item.avatarUrl}
          >
            {getInitials(item.name)}
          </Avatar>
          <Typography variant="body1">{item.name}</Typography>
        </div>,
        formatMoney(item.accountBalance),
        formatPercentage(item.cashRate),
        <EditLink onClick={() => handleEdit(item)}/>
      ]}
    selectedRows={scenarioCashAccounts}
    onItemAdded={(asset) => dispatch(addCashAccount(profile, scenario, asset))}
    onItemRemoved={(asset) => dispatch(removeCashAccount(profile, scenario, asset))}
  />
  <CashAccountForm open={openForm} onClose={() => setOpenForm(false)} onSave={handleSave} state={editingItem} onChange={handleChange}/>
</Fragment>
    
  );
};



export default ScenarioCashAccounts;
