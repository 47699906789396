import React, {useState, useEffect} from "react";
import _ from 'lodash';
import crossfilter from "crossfilter2";
import {json, format, timeFormat, timeMonth, timeYear, timeParse} from 'd3';
import { format as formatDate, parseISO } from 'date-fns'
import {mutate} from 'helpers/mutations';

export const Projections = Object.freeze({
  CASHFLOW: "CASHFLOW",
  EQUITY: "EQUITY",
})

export const Views = Object.freeze({
  [Projections.CASHFLOW]: ["Income", "Expenses", "Net Cashflow"],
  [Projections.EQUITY]: ["Assets", "Liabilities", "Networth"],
})

export const ViewAggregators = Object.freeze({
  "Networth" : period => {
    return {
      name: "networth",
      period: period[0].period,
      amount: _.sum(period.map(x => x.amount))
    }
  }
})

export const RangePresets = Object.freeze({
  TWELVE_MONTHS: "TWELVE_MONTHS",
  TEN_YEARS: "TEN_YEARS",
  FORTY_YEARS: "FORTY_YEARS"
});

export const CONTEXT_ACTIONS = Object.freeze({
  SET_PROJECTION: "SET_PROJECTION",
  SET_RANGE: "SET_RANGE",
  SET_VIEW: 'SET_VIEW'
});

export const CashflowContext = React.createContext("CashflowContext");
export const CashflowDispatchContext = React.createContext("CashflowDispatchContext");

export const dateFormatSpecifier = '%d-%m-%YT%I:%M:%S%pZ';
export const dateFormat = timeFormat(dateFormatSpecifier);
export const dateFormatParser = timeParse(dateFormatSpecifier);
export const numberFormat = format('.2f');

const toQuarter = (date) => {
  const month = date.getMonth();
  if (month <= 2) {
    return 'Q1';
  } else if (month > 2 && month <= 5) {
    return 'Q2';
  } else if (month > 5 && month <= 8) {
    return 'Q3';
  } else {
    return 'Q4';
  }
}

export const initialState = {
  projection: Projections.CASHFLOW,
  rangePreset: RangePresets.TEN_YEARS,
  view: "Income"
}

function selector(type) {
  switch (type) {
    case Projections.CASHFLOW: {
      return x => x.cashFlows;
    }
    case Projections.EQUITY: {
      return x => x.equity;
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`)
    }
  }
}

var thisYear = new Date().getFullYear();

export const RangeFilters = Object.freeze({
  [RangePresets.TWELVE_MONTHS]: (year) => {
    return year.getFullYear() <= thisYear + 1;
  },
  [RangePresets.TEN_YEARS]: (year) => {
    return year.getFullYear() <= thisYear + 10;
  },
  [RangePresets.FORTY_YEARS]:  (year) => {
    return year.getFullYear() <= thisYear + 40;
  },
});

function contextReducer(state = initialState, action) {
  switch (action.type) {
    case CONTEXT_ACTIONS.SET_PROJECTION: {
      const projection = action.projection;
      return mutate(state, {projection, view: Views[projection][0]});
    }
    case CONTEXT_ACTIONS.SET_RANGE: {
      const rangePreset = action.preset;
      return mutate(state, {rangePreset});
    }
    case CONTEXT_ACTIONS.SET_VIEW: {
      const view = action.view;
      return mutate(state, {view});
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const DataContext = (props) => { 

  const [ndx, setNdx] = useState(null);

  const [{rangePreset, projection, view}, dispatch] = React.useReducer(contextReducer, initialState);  

  useEffect(() => {
    if(props.data.timeline.length === 0){
      return;
    }

    const filter = RangeFilters[rangePreset];
    const select = selector(projection)
    const aggregate = ViewAggregators[view] || (p => p);

    let data = props.data.timeline
      .map(select)
      .map(aggregate)
      .flat();
  
    data.forEach(function (d) {
        // pre-calculate for better performance
        d.key = d.name.replace(/ /g,'');
        d.dd = parseISO(d.period );
        d.year = timeYear(d.dd);
        d.month = timeMonth(d.dd);
        d.quarter = toQuarter(d.month);
        d.amount = +d.amount;
    });

    data = data.filter(d => filter(d.dd))

    const ndx = crossfilter(data);   

    setNdx(ndx);

  },[props.data, projection, rangePreset, view]);

  if (!ndx) {
    return null;
  }
  return (
    <CashflowContext.Provider value={{ndx: ndx, rangePreset: rangePreset, projection, view}}>
      <CashflowDispatchContext.Provider value={dispatch}>
        {props.children}
      </CashflowDispatchContext.Provider>
    </CashflowContext.Provider>
  );
}

export {DataContext};
