    export const displayProps = {
        variant: 'outlined', 
        margin: 'normal',
        fullWidth: true,
    };

    export const validationProps = {
        required: true, 
    };

