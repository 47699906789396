import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const Loading = (props) => {
  return (
      
    <div >
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
    </div>
  );
}

export default Loading;
