import {
    PEOPLE_FETCHED,
  } from "./actions";
 
  const initialState = {
      people: []
  }

  const reducer = (state = initialState, action) => {
    switch(action.type) {
      case PEOPLE_FETCHED: 
        return action.list;
      default:
        return state;
    }
  }
  
  export { reducer, initialState };
  