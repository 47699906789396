// configureStore.js
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import filter from 'redux-storage-decorator-filter';
import createRootReducer from './reducers';

export const history = createBrowserHistory()

export default function configureStore() {

  const rootReducer = storage.reducer(createRootReducer(history));
  const engine = filter(createEngine(`fire-state`), ['profile']);
  
  const storageMiddleware = storage.createMiddleware(engine, ["@@router/LOCATION_CHANGE"]);
  const load = storage.createLoader(engine);

  const store = createStore(
    rootReducer,
    {},
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history),
        thunk,
        storageMiddleware
      ),
    ),
  )

  load(store)
  .then((newState) => console.log('Loaded state:', newState))
  .catch(() => console.log('Failed to load previous state'));

  return store
}