import React from 'react'
import OnboardingFlow from './OnboardingFlow'

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

class Onboarding extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <OnboardingFlow />
                </Paper>
            </Grid>
        )
    }
}
export default Onboarding
