import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const FrequencySelect = ({className, value, onChange }) => {
  return (
    <TextField
      className={className}
      id="select-frequency"
      select
      label="Frequency"
      value={value}
      onChange={onChange}
      helperText="The frequency of payments"
      variant="filled"
    >
      <MenuItem value="Daily">Daily</MenuItem>
      <MenuItem value="Monthly">Monthly</MenuItem>
      <MenuItem value="Quarterly">Quarterly</MenuItem>
      <MenuItem value="Yearly">Yearly</MenuItem>
    </TextField>
  );
};

export default FrequencySelect;
