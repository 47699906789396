import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {  MultiSelectTable } from 'components';
import {formatMoney, formatDate, formatPercentage} from 'helpers/formating'
import { getInitials } from 'helpers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";

import AssetForm from './AssetForm';
import EditLink from "../Editing/EditLink";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  Avatar,
  Typography,
} from '@material-ui/core';
import { addAsset, removeAsset, updateAsset } from 'views/Scenarios/components/Dashboard/actions';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const statusColors = {
  delivered: 'success',
  pending: 'info',
  refunded: 'danger'
};

const ScenarioAssets = props => {

  const {scenario, scenarioAssets, profileAssets, profile} = useSelector(state => ({
    scenario: state.scenarios.editor, 
    scenarioAssets: state.scenarios.scenarioAssets,
    profileAssets: state.scenarios.profileAssets,
    profile: state.profile
  }));

  const uris = new Set(scenarioAssets.map(x => x.links.asset.href));
  const profileOnlyAssets = profileAssets.filter(x => !uris.has(x.links.self.href));
  const classes = useStyles();

  const dispatch = useDispatch();

  const [openForm, setOpenForm] = useState(false);
  const [editingItem, setEditing] = useState(undefined);

  const handleEdit = (item) => {
    setEditing(item);
    setOpenForm(true);
  };

  const handleSave = (item) => {
    dispatch(updateAsset(profile, scenario, item));
    setOpenForm(false);
  };

  const handleChange = (item) => {
    setEditing(item);
  };  

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
        <MultiSelectTable
        rows={[...scenarioAssets, ...profileOnlyAssets]}
        columns={["Name", "Value", "Date from", "Date to", "Actions" ]}            
        renderRow={(item) => 
          [     
            <div className={classes.nameContainer}>
              <Avatar
                className={classes.avatar}
                src={item.avatarUrl}
              >
                {getInitials(item.name)}
              </Avatar>
              <Typography variant="body1">{item.name}</Typography>
            </div>,
            formatMoney(item.value),
            formatDate(item.startsFromUtc),
            formatDate(item.endsAtUtc),
            <EditLink onClick={() => handleEdit(item)}/>
          ]}
        selectedRows={scenarioAssets}
        onItemAdded={(asset) => dispatch(addAsset(profile, scenario, asset))}
        onItemRemoved={(asset) => dispatch(removeAsset(profile, scenario, asset))}
      />
    </MuiPickersUtilsProvider>
    <AssetForm
        open={openForm}
        onClose={() => setOpenForm(false)}
        onSave={handleSave}
        state={editingItem}
        onChange={handleChange}
      />
    </Fragment>
  );
};

export default ScenarioAssets;
