import { SELECT_PROFILE, PROFILE_CREATED } from './actions'

const initialState = {
}

function selectProfile(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState =  Object.assign({}, state, action.profile);
  return newState
}

function reducer(state = initialState, action){
    switch (action.type) {
        case SELECT_PROFILE:
            return selectProfile(state, action);
        case PROFILE_CREATED:
            return selectProfile(state, action);
    default:
        return state;
    }
}
export {reducer, initialState}