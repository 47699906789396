/* eslint-disable no-script-url */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

import Title from 'layouts/Title';
import { formatDate, formatMoney } from 'helpers/formating';

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  spinner: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  }
}));

export default function AmortisationSchedule(props) {
  const classes = useStyles();

  const hasData = props.schedule.length > 0;

  return (
    <React.Fragment>
      <Title>Amortisation Schedule</Title>
      {!hasData ? <div className={classes.spinner}><CircularProgress /> </div>:
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Period</TableCell>
              <TableCell>Payment Date</TableCell>
              <TableCell>Beginning Balance</TableCell>
              <TableCell>Payment Amount</TableCell>
              <TableCell>Extra Repayment</TableCell>
              <TableCell>Total Payment</TableCell>
              <TableCell>Principal Due</TableCell>
              <TableCell>Principal Paid</TableCell>
              <TableCell>Interest</TableCell>
              <TableCell>Ending Balance</TableCell>            
              <TableCell align="right">Cumulative Interest</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.schedule.map(row => (
              <TableRow key={row.period}>
                <TableCell>{row.period}</TableCell> 
                <TableCell>{formatDate(row.paymentDate)}</TableCell>
                <TableCell>{formatMoney(row.beginningBalance)}</TableCell>
                <TableCell>{formatMoney(row.paymentAmount)}</TableCell>
                <TableCell>{formatMoney(row.extraRepayment)}</TableCell>
                <TableCell>{formatMoney(row.totalPayment)}</TableCell>
                <TableCell>{formatMoney(row.principalDue)}</TableCell>
                <TableCell>{formatMoney(row.principalPaid)}</TableCell>
                <TableCell>{formatMoney(row.interest)}</TableCell>
                <TableCell>{formatMoney(row.endingBalance)}</TableCell>
                <TableCell align="right">{formatMoney(row.cumulativeInterest)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      }
    </React.Fragment>
  );
}