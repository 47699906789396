import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  Tooltip,
  Legend,
  CartesianGrid
} from "recharts";
import Title from "layouts/Title";
import numeral from "numeral";
import { formatDate } from 'helpers/formating';

function formatYAxis(y) {
  return numeral(y).format("0.0a");
}

const Chart = props => {
  console.log(props.forecast);
  var comparison = props.schedule.map(x => ({
    paymentDate: formatDate(x.paymentDate),
    endingBalance: x.endingBalance
  }));
  return (
    <React.Fragment>
      <Title>Loan value</Title>
      <ResponsiveContainer>
        <LineChart
          data={comparison}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24
          }}
        >
          <XAxis dataKey="paymentDate" />
          <YAxis tickFormatter={formatYAxis}>
            <Label angle={270} position="left" style={{ textAnchor: "middle" }}>
              Value ($)
            </Label>
          </YAxis>
          <Tooltip />
          <Legend />

          <Line
            type="monotone"
            dataKey="endingBalance"
            stroke="#556CD6"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default Chart;
