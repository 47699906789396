import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, TextField } from "@material-ui/core/";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import { MoneyInput, PercentageInput } from "components/FinancialFields";
import FrequencySelect from "components/Frequency";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const styles = theme => ({
  personDetails: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200
    }
  }
});

const StyledTextField = props => (
  <TextField variant="outlined" margin="normal" required {...props} />
);

const Form = ({ className, classes, state, handleChange }) => {
  const textProps = { variant: "outlined", margin: "normal", required: true };
  return (
    <Card className={className}>
      <CardHeader title="Details" />
      <CardContent className={classes.personDetails}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
          <div>
            <StyledTextField
              name="name"
              label="Liability name"
              value={state["name"]}
              onChange={handleChange("name")}
            />
              <KeyboardDatePicker
                {...textProps}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                id="date-picker-inline"
                label="Loan start date"
                value={state["startedOnUtc"]}
                onChange={handleChange("startedOnUtc")}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </div>

            <div>
            <MoneyInput
              {...textProps}
              name="value"
              label="Value"
              value={state["value"]}
              onChange={handleChange("value")}
            />
            <PercentageInput
              {...textProps}
              id="interestRate"
              name="interestRate"
              label="Interest rate"
              value={state["interestRate"]}
              onChange={handleChange("interestRate")}
            />
            <KeyboardDatePicker
              {...textProps}
              inputVariant="outlined"
              format="dd/MM/yyyy"
              id="date-picker-inline"
              label="Valued at"
              value={state["valuedAtUtc"]}
              onChange={handleChange("valuedAtUtc")}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </div>
          <div>
            <StyledTextField
              name="loanTerm"
              label="Loan Term"
              type="number"
              value={state["loanTerm"]}
              onChange={handleChange("loanTerm")}
            />
            <PercentageInput
              {...textProps}
              id="interestOnlyPeriod"
              name="interestOnlyPeriod"
              label="Interest Only Period"
              value={state["interestOnlyPeriod"]}
              onChange={handleChange("interestOnlyPeriod")}
            />
          </div>
          <div>
            <MoneyInput
              {...textProps}
              name="extraRepayment"
              label="Extra Repayment"
              value={state["extraRepayment"]}
              onChange={handleChange("extraRepayment")}
            />
            <FrequencySelect
              value={state["frequency"]}
              onChange={handleChange("frequency")}
            />
          </div>
        </MuiPickersUtilsProvider>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(Form);
