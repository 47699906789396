import { NEXT, BACK, RESET, FINISH } from './actions'

const initialState = {
  activeStep: 0,
  complete: false,
  input: {
    dateOfBirth: '1981-02-09',
    currentNetworth: 300000,
    incomeAfterTax: 95000,
    incomeBeforeTax: 130000,
    growthRate: .08,
    estimatedInflationRate: .03,
    annualSavings: 20000,
    superBalance: 120000,
    superEmployerGuarantee: .095,
    retirementAge: 55,
    desiredIncome: 55000
  }
}


function nextStep(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState.activeStep = state.activeStep + 1;
  newState.input =  Object.assign({}, state.input, action.input);

  return newState
}

function backStep(state = initialState, action) {
    var newState = Object.assign({}, state);
    newState.activeStep = state.activeStep - 1 ;
    newState.input =  Object.assign({}, state.input, action.input);
    return newState
}

function reset(state = initialState, action) {
    return initialState;
}

function finish(state, action){
    return Object.assign({}, state, { complete: true })
}

function takeStep(state = initialState, action){
    switch (action.type) {
        case NEXT:
            return nextStep(state, action);
        case BACK:
            return backStep(state, action);
        case RESET:
            return reset(state, action);
        case FINISH:
            return finish(state, action);
    default:
        return state;
    }
}

const onboardingReducer = takeStep;

export default onboardingReducer