import React, { useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {ScenarioAssets} from '../ScenarioAssets';
import {ScenarioLiabilities} from '../ScenarioLiabilities';
import {ScenarioIncome} from '../ScenarioIncome';
import {ScenarioExpenses} from '../ScenarioExpenses';
import {ScenarioCashAccounts} from '../ScenarioCashAccounts';

import PropTypes from 'prop-types';
import {TabPanel} from 'components';
import {
  Card,
  CardActions,
  Paper, Tabs, Tab,
  CardContent,
  Button,
  Divider,
  Avatar,
  Typography,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const statusColors = {
  delivered: 'success',
  pending: 'info',
  refunded: 'danger'
};

const ScenarioConfiguration = props => {
  const { className, ...rest } = props;
  
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Paper>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Cash Accounts" />
          <Tab label="Income" />
          <Tab label="Expenses" />
          <Tab label="Assets" />
          <Tab label="Liabilities" />
        </Tabs>
      </Paper>
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <TabPanel value={value} index={0}>
              <ScenarioCashAccounts {...props} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ScenarioIncome {...props} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ScenarioExpenses {...props} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ScenarioAssets {...props} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ScenarioLiabilities {...props} />
            </TabPanel>
        
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button color="primary" size="small" variant="text">
          View all <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

ScenarioAssets.propTypes = {
  className: PropTypes.string
};

export default ScenarioConfiguration;
