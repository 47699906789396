import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  InputBase,
  Select,
  IconButton,
  MenuItem,
  FormControl
} from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    margin: "auto"
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
});

const UrlBar = ({url, setUrl, onClick}) => {
  const classes = useStyles();
  const [method, setMethod] = useState('GET');

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      onClick({url, method});
    }
  };

  return (
    <Paper className={classes.root}>
     <FormControl variant="outlined">
      <Select
          value={method}
          onChange={e => setMethod(e.target.value)}>
        <MenuItem value={"GET"}>GET</MenuItem>
        <MenuItem value={"POST"}>POST</MenuItem>
        <MenuItem value={`PUT`}>PUT</MenuItem>
        <MenuItem value={`DELETE`}>DELETE</MenuItem>
      </Select>
      </FormControl>
      <InputBase
        className={classes.input}
        value={url}
        onChange={e => setUrl(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Enter an api endpoint"
      />
      <IconButton
        className={classes.iconButton}
        aria-label="Search"
        onClick={() => onClick({url, method})}
      >
        <SendIcon />
      </IconButton>
    </Paper>
  );
};

export default UrlBar;
