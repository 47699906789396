import React, { useReducer } from "react";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {Grid} from "@material-ui/core/";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import * as actions from "../../actions";
import AssetType from "./AssetType";
import AssetDetails from "./AssetDetails";


const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  card: {
    marginTop: theme.spacing(4),
  },
  buttons: {
    marginTop: theme.spacing(3)
  }
});

function reducer(state, {name, value}){
  return {
    ...state,
    [name]: value
  }
}

const CreateAsset = ({classes, app, profile, createAsset}) => {
  const [asset, setAsset] = useReducer(reducer, {
      name: "",
      assetType: "Shares",
    });

  const parseInput = (target) => {
    return target.type == "number" ? parseInt(target.value) : target.value;
  }

  const value = event  => {
    const { target } = event;
    return target !== undefined ? parseInput(target) : event;
  } 
  
  const handleAssetChange = name => event => {
    setAsset({ name, value: value(event) });
  };

  return (
    <Grid item xs={12}>
      <AssetType className={classes.card} handleChange={handleAssetChange} state={asset} />
      <AssetDetails className={classes.card} handleChange={handleAssetChange} state={asset} />
      <div className={classes.buttons} style={{ flex: 1 }}>
        <Button variant="contained" color={ app.isLoading ? "default" : "primary" }  
          onClick={() => createAsset(profile, asset)}
          className={classes.button}>
          Create asset
        </Button>
      </div>
    </Grid>
  );
};

const mapStateToProps = state => {
  return Object.assign({}, { app: state.app, asset: state.editor, profile: state.profile });
};

const mapDispatchToProps = {
  ...actions,
  push
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
);

export default enhance(CreateAsset);
