import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector} from "react-redux";
import { makeStyles } from '@material-ui/styles';
import {  MultiSelectTable } from 'components';
import {formatMoney, formatDate} from 'helpers/formating'
import { getInitials } from 'helpers';
import IncomeForm from './IncomeForm';
import {updateIncome} from '../../actions'
import EditLink from '../Editing/EditLink';

import {
  Avatar,
  Typography,
} from '@material-ui/core';
import { addIncome, removeIncome } from 'views/Scenarios/components/Dashboard/actions';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));


const ScenarioIncome = () => {

  const {scenario, scenarioIncome, profileIncome, profile} = useSelector(state => ({
    scenario: state.scenarios.editor, 
    scenarioIncome: state.scenarios.scenarioIncome,
    profileIncome: state.scenarios.profileIncome,
    profile: state.profile
  }));


  const uris = new Set(scenarioIncome.map(x => x.links.income.href));
  const profileOnlyIncome = profileIncome.filter(x => !uris.has(x.links.self.href));

  const classes = useStyles();

  const dispatch = useDispatch();

  const [openForm, setOpenForm] = useState(false);
  const [editingItem, setEditing] = useState(undefined);

  const handleEdit = (item) => {
    setEditing(item);
    setOpenForm(true);
  };

  const handleSave = (item) => {
    dispatch(updateIncome(profile, scenario, item))
    setOpenForm(false);
  };

  const handleChange = item => {
    setEditing(item);
  }

  return (
    <Fragment>
      <MultiSelectTable
    rows={[...scenarioIncome, ...profileOnlyIncome]}
    columns={["Name", "Amount", "Date from", "Date to", "Actions" ]}
    renderRow={(item) => 
      [     
        <div className={classes.nameContainer}>
          <Avatar
            className={classes.avatar}
            src={item.avatarUrl}
          >
            {getInitials(item.name)}
          </Avatar>
          <Typography variant="body1">{item.name}</Typography>
        </div>,
        formatMoney(item.amount),
        formatDate(item.startsFromUtc),
        formatDate(item.endAtUtc),
        <EditLink onClick={() => handleEdit(item)}/>
      ]}
    selectedRows={scenarioIncome}
    onItemAdded={(asset) => dispatch(addIncome(profile, scenario, asset))}
    onItemRemoved={(asset) => dispatch(removeIncome(profile, scenario, asset))}
  />
  <IncomeForm open={openForm} onClose={() => setOpenForm(false)} onSave={handleSave} state={editingItem} onChange={handleChange}/>
</Fragment>
    
  );
};



export default ScenarioIncome;
