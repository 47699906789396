/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemAvatar, Typography } from '@material-ui/core';
import ListSubheader from '@material-ui/core/ListSubheader';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';  

  const useStyles = makeStyles(theme => ({
  root: {
    padding: "0px 8px",
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 15,
  },
  itemText: {
    '& a': {
      textDecoration: 'none',
      color: '#263238',
      fontSize: '14px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '-0.05px'
    }
  },  
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  orange: {
    marginRight: 16,
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    textDecoration: 'none',
  },
  purple: {
    marginRight: 26,
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    textDecoration: 'none',
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink onClick={props.onClick} {...props} />
  </div>
));

const ProfileNav = props => {
  const { profiles, className, onClose, onProfileSelected, ...rest } = props;

  const classes = useStyles();

  const getAvatar = (profile, i) => {
    var firstChar = profile.name.substring(0,1);
    return <Avatar 
          component={CustomRouterLink} 
          to="/"
          src={`/images/avatars/avatar_${i + 1}.png`}>
        {firstChar}
      </Avatar>
  }

  return (
    <List
      className={classes.root}
      {...rest}
      subheader={
        <ListSubheader disableGutters component="li" id="profile-list-subheader">
          Recent profiles
        </ListSubheader>
      }
    >
      {profiles.map((profile, i) => (
          <ListItem
            disableGutters
            key={i}
          >
            <ListItemAvatar>{getAvatar(profile, i)}</ListItemAvatar>
            <ListItemText className={classes.itemText}>
              <Typography 
                to="/"
                onClick={() => onProfileSelected(profile)}
                variant="h6" noWrap>{profile.name} </Typography>
            </ListItemText>
        
          </ListItem>
      ))}
    </List>
  );
};

ProfileNav.propTypes = {
  className: PropTypes.string,
  profiles: PropTypes.array.isRequired
};

export default ProfileNav;
