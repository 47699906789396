import React, { Fragment, useState, useEffect } from 'react'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AmortisationSchedule from './components/AmortisationSchedule';
import PayOffDate from './components/PayOffDate';
import Chart from './components/Chart';
import  { API } from 'aws-amplify';
import { useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

const Amortisation = ({ classes }) => {
    const [schedule, setSchedule] = useState([]);
    const { profile } = useSelector(state => state);

    const { id } = useParams();

    useEffect(() => {
        if (!profile.links){
            return;
        }
        let returnAxios = { 
            response: true  
        }

        API.get("API", `/profiles/${profile.id}/liabilities/${id}/schedule`, returnAxios)
        .then(resp => { 
            setSchedule(resp.data);
        })
        .catch(error => {
            console.log(error.response);
        })
    }, [profile.id]);

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return (
        <Fragment>
            <Grid item xs={12} md={8} lg={9}>                    
                <Paper className={fixedHeightPaper}>
                    <Chart schedule={schedule}></Chart> 
                </Paper>                    
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
                <Paper className={fixedHeightPaper}>
                    <PayOffDate schedule={schedule} />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <AmortisationSchedule schedule={schedule}/>
                </Paper>
            </Grid>
        </Fragment>
    )
}
export default Amortisation;