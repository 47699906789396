import { API } from "aws-amplify";
import * as rootActions  from 'rootActions'
import * as people from 'views/People/actions';
import {selectScenario} from 'views/Scenarios/actions';
import {init, fetchList} from 'helpers/api';

/*
 * action types
 */

export const SCENARIO_FORECAST_FETCHED = 'SCENARIO_FORECAST_FETCHED';
export const SCENARIO_ASSETS_FETCHED = 'SCENARIO_ASSETS_FETCHED';
export const SCENARIO_ASSET_ADDED = 'SCENARIO_ASSETS_ADDED';
export const PROFILE_ASSETS_FETCHED = 'PROFILE_ASSETS_FETCHED';
export const PROFILE_LIABILITIES_FETCHED = 'PROFILE_LIABILITIES_FETCHED';
export const SCENARIO_LIABILITIES_FETCHED = 'SCENARIO_LIABILITIES_FETCHED';
export const PROFILE_INCOME_FETCHED = 'PROFILE_INCOME_FETCHED';
export const SCENARIO_INCOME_FETCHED = 'SCENARIO_INCOME_FETCHED';
export const PROFILE_EXPENSES_FETCHED = 'PROFILE_EXPENSES_FETCHED';
export const SCENARIO_EXPENSES_FETCHED = 'SCENARIO_EXPENSES_FETCHED';

export const PROFILE_CASH_ACCOUNTS_FETCHED = 'PROFILE_CASH_ACCOUNTS_FETCHED';
export const SCENARIO_CASH_ACCOUNTS_FETCHED = 'SCENARIO_CASH_ACCOUNTS_FETCHED';


export function scenariosAssetsList(scenarioAssets) {
  return {type: SCENARIO_ASSETS_FETCHED, scenarioAssets};
}

export function profileAssetsList(profileAssets) {
  return {type: PROFILE_ASSETS_FETCHED, profileAssets};
}

export function scenarioForecastFetched(forecast) {
  return {type: SCENARIO_FORECAST_FETCHED, forecast};
}

export function fetchScenarioForecast(scenarioUri, profile) {
  return async dispatch => {
    dispatch(rootActions.loading());
    
    try {
      var scenario = await API.get("API", scenarioUri);
      dispatch(selectScenario(scenario));
      
      var forecast = API.get("API", scenario.links.forecast.href);

      await fetchConfiguration(dispatch, scenario, profile);

      dispatch(scenarioForecastFetched(await forecast));
    }
    catch (err){
      console.log(err);
    }

    dispatch(rootActions.loaded());
  }
}

export function reFetchScenarioForecast(scenario) {
  return async dispatch => {
    dispatch(rootActions.loading());
    
    try {
      var forecast = API.get("API", scenario.links.forecast.href);
      dispatch(scenarioForecastFetched(await forecast));
    }
    catch (err){
      console.log(err);
    }

    dispatch(rootActions.loaded());
  }
}

async function fetchConfiguration(dispatch, scenario, profile) {
  const fetching = [
    fetchPeople(profile),
    fetchScenarioAssets(scenario),
    fetchProfileAssets(profile),    
    fetchScenarioIncomes(scenario),
    fetchProfileIncomes(profile),
    fetchScenarioLiabilities(scenario),
    fetchProfileLiabilities(profile),
    fetchScenarioExpenses(scenario),
    fetchProfileExpenses(profile),
    fetchScenarioCashAccounts(scenario),
    fetchProfileCashAccounts(profile)];

  await Promise.all(fetching.map(f => f(dispatch)));
}

export const fetchPeople = (profile) => fetchList(profile, "people", people.PEOPLE_FETCHED);
export const fetchScenarioAssets = (scenario) => fetchList(scenario, "scenarioAssets", SCENARIO_ASSETS_FETCHED);
export const fetchProfileAssets = (profile) => fetchList(profile, "assets", PROFILE_ASSETS_FETCHED);
export const fetchScenarioIncomes = (scenario) => fetchList(scenario, "scenarioIncomes",SCENARIO_INCOME_FETCHED);
export const fetchProfileIncomes = (profile) => fetchList(profile, "incomes", PROFILE_INCOME_FETCHED);
export const fetchScenarioLiabilities = (scenario) => fetchList(scenario, "scenarioLiabilities", SCENARIO_LIABILITIES_FETCHED);
export const fetchProfileLiabilities = (profile) => fetchList(profile, "liabilities", PROFILE_LIABILITIES_FETCHED);
export const fetchScenarioExpenses = (scenario) => fetchList(scenario, "scenarioExpenses", SCENARIO_EXPENSES_FETCHED);
export const fetchProfileExpenses = (profile) => fetchList(profile, "expenses", PROFILE_EXPENSES_FETCHED);
export const fetchScenarioCashAccounts = (scenario) => fetchList(scenario, "scenarioCashAccounts", SCENARIO_CASH_ACCOUNTS_FETCHED);
export const fetchProfileCashAccounts = (profile) => fetchList(profile, "cashAccounts", PROFILE_CASH_ACCOUNTS_FETCHED);


export const addAsset = (profile, scenario, asset) => add(profile, scenario, asset, "scenarioAssets", fetchScenarioAssets);
export const updateAsset = (profile, scenario, asset) => update(profile, scenario, asset, fetchScenarioAssets);
export const removeAsset = (profile, scenario, asset) => remove(profile, scenario, asset, "scenarioAssets",fetchScenarioAssets);

export const addLiability = (profile, scenario, liability) => add(profile, scenario, liability, "scenarioLiabilities", fetchScenarioLiabilities);
export const updateLiability = (profile, scenario, liability) => update(profile, scenario, liability, fetchScenarioLiabilities);
export const removeLiability = (profile, scenario, liability) => remove(profile, scenario, liability, "scenarioLiabilities", fetchScenarioLiabilities);

export const addIncome = (profile, scenario, income) => add(profile, scenario, income, "scenarioIncomes", fetchScenarioIncomes);
export const updateIncome = (profile, scenario, income) => update(profile, scenario, income, fetchScenarioIncomes);
export const removeIncome = (profile, scenario, income) => remove(profile, scenario, income, "scenarioIncomes", fetchScenarioIncomes);

export const addExpense = (profile, scenario, expense) => add(profile, scenario, expense, "scenarioExpenses", fetchScenarioExpenses);
export const updateExpense = (profile, scenario, expense) => update(profile, scenario, expense,  fetchScenarioExpenses);
export const removeExpense = (profile, scenario, expense) => remove(profile, scenario, expense, "scenarioExpenses", fetchScenarioExpenses);

export const addCashAccount = (profile, scenario, account) => add(profile, scenario, account, "scenarioCashAccounts", fetchScenarioCashAccounts);
export const updateCashAccount = (profile, scenario, account) => update(profile, scenario, account,  fetchScenarioCashAccounts);
export const removeCashAccount = (profile, scenario, account) => remove(profile, scenario, account, "scenarioCashAccounts", fetchScenarioCashAccounts)

function add(profile, scenario, resource, type, refetch) {
  return async dispatch => {
    dispatch(rootActions.loading());

    try {
      const link = scenario.links[type];
      await fetch(process.env.REACT_APP_API_BASE_URL + link.href, await init(link, {
         method: 'POST',
         body: resource.links.self 
      }));

      await dispatch(refetch(scenario));

    } catch (err) {
      console.log(err);
    }

    dispatch(reFetchScenarioForecast(scenario, profile));
    dispatch(rootActions.loaded());
  }
}


function update(profile, scenario, resource, refetch) {
  return async dispatch => {
    dispatch(rootActions.loading());

    try {
      const link = resource.links.self;

      await fetch(process.env.REACT_APP_API_BASE_URL + link.href, await init(link, { method: 'PUT', body: resource }));

      await dispatch(refetch(scenario));

    } catch (err) {
      console.log(err);
    }

    dispatch(reFetchScenarioForecast(scenario, profile));
    dispatch(rootActions.loaded());
  }
}

function remove(profile, scenario, resource, type, refetch) {
  return async dispatch => {
    dispatch(rootActions.loading());

    try {
      const link = scenario.links[type];

      await fetch(process.env.REACT_APP_API_BASE_URL + link.href, await init(link, { method: 'DELETE', body: resource.links.self }));

      await dispatch(refetch(scenario));

    } catch (err) {
      console.log(err);
    }

    dispatch(reFetchScenarioForecast(scenario, profile));
    dispatch(rootActions.loaded());
  }
}