import { API } from "aws-amplify";
import * as rootActions  from 'rootActions'
import { push } from 'connected-react-router'
import {reFetchScenarioForecast} from './components/Dashboard/actions'

/*
 * action types
 */

export const SELECT_SCENARIO = 'SELECT_SCENARIO'
export const SCENARIO_CREATED = 'SCENARIO_CREATED'
export const SCENARIOS_FETCHED = 'SCENARIOS_FETCHED';
export const SCENARIO_DELETED = 'SCENARIO_DELETED';


export function selectScenario(scenario) {
  return { type: SELECT_SCENARIO, scenario }
}

export function scenarioCreated(scenario) {
    return { type: SCENARIO_CREATED, scenario };
}

export function scenariosList(scenarios) {
  return {type: SCENARIOS_FETCHED, scenarios};
}


export function scenarioDeleted(scenario) {
  return {type: SCENARIO_DELETED, scenario}
}

export function createScenario(profile, scenario) {
  return async dispatch => {

    dispatch(rootActions.loading());

    try {
      const href = profile.links.scenarios.href;
      const scenarioResponse = await API.post("API", href, { body: scenario } );
      dispatch(scenarioCreated(scenarioResponse));
      dispatch(push(`/scenarios${scenarioResponse.links.self.href}`));
    } catch (err) {
      console.log(err);
    }

    dispatch(rootActions.loaded());

  }
}

export function updateScenario(scenario) {
  return async dispatch => {

    dispatch(rootActions.loading());

    try {
      const href = scenario.links.self.href;
      const scenarioResponse = await API.put("API", href, { body: scenario } );
      dispatch(selectScenario(scenarioResponse));
      dispatch(reFetchScenarioForecast(scenario));
    } catch (err) {
      console.log(err);
    }

    dispatch(rootActions.loaded());
  }
}

export function fetchScenarioList(profile) {
  return async dispatch => {
    dispatch(rootActions.loading());
    const scenario = await API.get("API", profile.links.scenarios.href);
    dispatch(scenariosList(scenario.list));
    dispatch(rootActions.loaded());
  }
}

async function deleteAction(dispatch, profile, scenario) {
  dispatch(rootActions.loading());

  try {
    await API.del("API", scenario.links.self.href);

    dispatch(scenarioDeleted(scenario));
  } catch (err) {
    console.log(err);
  }

  dispatch(fetchScenarioList(profile));

  dispatch(rootActions.loaded());
}

export function deleteScenario(profile, scenario) {
  return async dispatch => {
    Promise.all(scenario.map(i => deleteAction(dispatch, profile, i)));
  }
}
