import React, { useState, Fragment} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { MultiSelectTable } from "components";
import { formatMoney, formatDate, formatPercentage } from "helpers/formating";
import { getInitials } from "helpers";
import {updateExpense} from '../../actions';

import ExpenseForm from './ExpenseForm';
import EditLink from "../Editing/EditLink";

import { Avatar, Typography } from "@material-ui/core";
import {
  addExpense,
  removeExpense,
} from "views/Scenarios/components/Dashboard/actions";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 800,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const statusColors = {
  delivered: "success",
  pending: "info",
  refunded: "danger",
};

const ScenarioExpenses = (props) => {
  const { scenario, scenarioExpenses, profileExpenses, profile } = useSelector(
    (state) => ({
      scenario: state.scenarios.editor,
      scenarioExpenses: state.scenarios.scenarioExpenses,
      profileExpenses: state.scenarios.profileExpenses,
      profile: state.profile,
    })
  );

  const uris = new Set(scenarioExpenses.map((x) => x.links.expense.href));
  const profileOnlyExpenses = profileExpenses.filter(
    (x) => !uris.has(x.links.self.href)
  );

  const classes = useStyles();

  const dispatch = useDispatch();

  const [openForm, setOpenForm] = useState(false);
  const [editingItem, setEditing] = useState(undefined);

  const handleEdit = (item) => {
    setEditing(item);
    setOpenForm(true);
  };

  const handleSave = (item) => {
    dispatch(updateExpense(profile, scenario, item));
    setOpenForm(false);
  };

  const handleChange = (item) => {
    setEditing(item);
  };

  return (
    <Fragment>
      <MultiSelectTable
        rows={[...scenarioExpenses, ...profileOnlyExpenses]}
        columns={["Name", "Amount", "Date from", "Date to", "Actions"]}
        renderRow={(item) => [
          <div className={classes.nameContainer}>
            <Avatar className={classes.avatar} src={item.avatarUrl}>
              {getInitials(item.name)}
            </Avatar>
            <Typography variant="body1">{item.name}</Typography>
          </div>,
          formatMoney(item.amount),
          formatDate(item.startsFromUtc),
          formatDate(item.endAtUtc),
          <EditLink onClick={() => handleEdit(item)}/>
        ]}
        selectedRows={scenarioExpenses}
        onItemAdded={(asset) => dispatch(addExpense(profile, scenario, asset))}
        onItemRemoved={(asset) =>
          dispatch(removeExpense(profile, scenario, asset))
        }
      />
      <ExpenseForm
        open={openForm}
        onClose={() => setOpenForm(false)}
        onSave={handleSave}
        state={editingItem}
        onChange={handleChange}
      />
    </Fragment>
  );
};

export default ScenarioExpenses;
