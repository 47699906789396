import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ScenarioList from './List';
import {fetchScenarioList}  from 'views/Scenarios/actions';
import { styles } from 'dashboardStyles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(styles);

const Scenario = () => {
    const {profile, app, income} = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        if (profile.links === undefined)
          return;

        dispatch(fetchScenarioList(profile));
    }, [profile.id]);

    const Loading = () => (
      <div>    
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
      </div>);

     return <Grid item xs={12}>
      {app.isLoading ? <Loading/> : <ScenarioList income={income.list} />}
     </Grid>;
}

export default Scenario;