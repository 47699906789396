import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";
import { API } from "aws-amplify";
import { ProfileNav } from "./components";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { push } from "connected-react-router";
import * as actions from 'views/Profiles/actions';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 280
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const ProfileSwitcher = ({ open, variant, onClose, className, selectProfile, fetchProfile }) => {
  const classes = useStyles();

  const profilesEndpoint = useSelector(state => state.api.links.profiles.href);

  const [profiles, setProfiles] = useState([]);

  const {profile, forceRefresh} = useSelector(({app, profile}) => { 
    return { profile, forceRefresh: app.forceRefresh }
  });

  useEffect(() => {
    if (forceRefresh) {
      fetchProfile(profile);
    }
  }, [forceRefresh]);

  useEffect(() => {
    if (!open) {
      return;
    }

    const getProfiles = async () => {
      const response = await API.get("API", profilesEndpoint);
      setProfiles(response.list);
    };

    getProfiles();
  }, [open]);

  const handleProfileSelected = profile => {
    selectProfile(profile);
    onClose();
  }

  return (
    <Drawer
      anchor="right"  
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div className={clsx(classes.root, className)}>
        <ProfileNav
          className={classes.nav}
          profiles={profiles}
          onProfileSelected={handleProfileSelected}
        />
      </div>
    </Drawer>
  );
};

ProfileSwitcher.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return Object.assign({}, state);
};

const mapDispatchToProps = {
  ...actions,
  push
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));
export default enhance(ProfileSwitcher);
