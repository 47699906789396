import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles';
import { Grid, LinearProgress } from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import {FireCard} from 'components';
import {fetchFinancialSummary} from './actions';

import {
  Networth,
  PassiveIncome,
  TasksProgress,
  YtdIncrease,
  IncomeGrowth,
  AssetAllocation,
  LatestProducts,
  LatestOrders
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  primaryBackground: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  
  progress: {
    marginTop: theme.spacing(3)
  }
}));

const Overview = () => {
  const classes = useStyles();

  const { profile, overview, app } = useSelector(state => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (profile.links === undefined)
      return;

    dispatch(fetchFinancialSummary(profile));
}, [profile.id, profile.links]);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Networth value={overview.financialSummary.networth} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <PassiveIncome />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <FireCard 
              title="FI PROGRESS" 
              value="25.5%" 
              iconColor={classes.primaryBackground}
              icon={InsertChartIcon}>
            <LinearProgress
              className={classes.progress}
              value={25.5}
              variant="determinate"/>
          </FireCard>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <YtdIncrease />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <IncomeGrowth />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <AssetAllocation />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <LatestProducts />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestOrders />
        </Grid>
      </Grid>
    </div>
  );
};

export default Overview;
