import { API } from "aws-amplify";
import * as rootActions  from 'rootActions'
import { push } from 'connected-react-router'

/*
 * action types
 */

export const SELECT_ASSET = 'SELECT_ASSET';
export const ASSET_CREATED = 'ASSET_CREATED';
export const ASSET_DELETED = 'ASSET_DELETED';
export const ASSETS_FETCHED = 'ASSETS_FETCHED';

export function selectAsset(asset) {
  return { type: SELECT_ASSET, asset }
}

export function assetCreated(asset) {
    return { type: ASSET_CREATED, asset }
}

export function assetDeleted(asset) {
  return { type: ASSET_DELETED, asset }
}

export function assetsList(assets) {
  return { type: ASSETS_FETCHED, assets }
}

export function createAsset(profile, asset) {
  return async dispatch => {

    dispatch(rootActions.loading());

    try {
      const href = getLink(profile, asset);
      const assetResponse = await API.post("API", href, { body: asset } );
      dispatch(assetCreated(assetResponse));
    } catch (err) {
      console.log(err);
    }

    dispatch(rootActions.loaded());

    dispatch(push('/assets'));
  }
}

const getLink = (profile, asset) => {
  console.log(asset.assetType);
  return profile.
  links[asset.assetType].href;
}

export function fetchAssetsList(profile) {
  return async dispatch => {
    dispatch(rootActions.loading());
    var response = await API.get("API", profile.links.assets.href);

    const assets = await Promise.all(
      response.list.map(l => API.get("API", l.href))
    );

    dispatch(assetsList(assets));
    dispatch(rootActions.loaded());
  }
}

async function deleteAction(dispatch, profile, asset) {
  dispatch(rootActions.loading());

  try {
    await API.del("API", asset.links.self.href);

    dispatch(assetDeleted(asset));
  } catch (err) {
    console.log(err);
  }

  dispatch(fetchAssetsList(profile));

  dispatch(rootActions.loaded());
}

export function deleteAssets(profile, liabilities) {
  return async dispatch => {
    Promise.all(liabilities.map(l => deleteAction(dispatch, profile, l)));
  }
}

