import React, {useRef} from 'react';
import TextField from '@material-ui/core/TextField';
import { 
    Grid,
    Typography,
} from '@material-ui/core';
import {wizardStep} from '../../../../components/Wizard';
import {MoneyInput, PercentageInput} from "../../../../components/FinancialFields";
import {useStyles} from '../useStyles';
import {useControlProps} from '../useControlProps';

const fields = Object.freeze({
    occupation: 'occupation',
    superannuation: 'superannuation',
    retirementAge: 'retirementAge',
});

const initialState = Object.freeze({
    occupation: '',
    superannuation: '',
    retirementAge: '',
});

const Occupation = ({state, setState, canProceed}) => {
    const classes = useStyles();
    const formRef = useRef(null);
    const {controlProps} = useControlProps({state, setState, initialState, formRef, canProceed});

    return (
        <Grid container spacing={1}>
            <Grid item xs={3}>

                <form noValidate autoComplete="off">
                    <TextField 
                        {...controlProps(fields.occupation)}
                        label="Occupation"
                    />
                    <PercentageInput
                        {...controlProps(fields.superannuation)}
                        label="Supeannuation deduction, %"
                    />
                    <TextField 
                        {...controlProps(fields.retirementAge)}
                        label="Retirement age"
                        type="number"
                    />
                </form>

            </Grid>
            <Grid item xs={9}>

                <div className={classes.main}>
                    <Typography variant="h1" component="h2" gutterBottom align="center">
                        $140,000
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom align="center">
                        Net cashflow
                    </Typography>
                </div> 

            </Grid>
        </Grid>
    );
};

const _ = wizardStep(Occupation)
export {_ as Occupation};
