import React, { useEffect } from 'react';
import './App.css'
import Routes from 'routes';
import { authenticate } from 'views';
import { Provider, connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './configureStore'
import { ThemeProvider } from '@material-ui/styles';
import { ApiRootProvider, UserInfoProvider } from 'components/ApiRootProvider';
import { AccountProvider } from 'components/AccountProvider';
import {compose} from 'redux'

import theme from './theme';

import Amplify from 'aws-amplify';
import awsconfig from 'awsconfig';
import 'highlight.js/styles/atom-one-dark.css'
import axios from 'axios'

Amplify.configure(awsconfig);


const App = (props) => {
  return (
    <ConnectedRouter history={history}> { /* place ConnectedRouter under Provider */}
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <ThemeProvider theme={theme}>
        <ApiRootProvider>
          <AccountProvider history={history}>
            <Routes/>
          </AccountProvider>
        </ApiRootProvider>
      </ThemeProvider>
    </ConnectedRouter>
  );
}

const enhance = compose(
  connect(null, null), 
  authenticate,
)

const AppWithDispatch = enhance(App)

const AppWithReduxStore = (props) => {
  return (
      <Provider store={configureStore()}>
       <AppWithDispatch/>
      </Provider>    
  );
}

export default AppWithReduxStore;
