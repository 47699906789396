import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import {DeleteOutline,FileCopyOutlined} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
      padding: 16
    },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    actions: {
      display: 'flex',
      flexWrap: 'wrap', 
      justifyContent: 'center',
      '& button': {
        marginLeft: 16,
      }
    },
    icon: {
      marginRight: 8
    }
  })
);

const ActionsBar = ({count, open, onDelete, onDuplicate }) => {
    const classes = useStyles();

    return <Drawer anchor="bottom" variant="persistent" open={open}>
      <div className={classes.root}>
        <Grid container alignItems="center">
          <Grid item md={3} >
            <Typography variant='subtitle1' color='textSecondary'>{`${count} selected`}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.actions}>
              <Button onClick={onDuplicate}>
                <FileCopyOutlined className={classes.icon}/>Duplicate
                </Button>
              <Button onClick={onDelete}>
                <DeleteOutline className={classes.icon}/>Delete
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Drawer>
};

export default ActionsBar;