import React from 'react'

export default function withFlow(Component) {
    const steps = Object.entries({
            'Current Position': {
                fields: [{                
                    label: 'Date of birth',
                    type: 'date',
                    autoComplete: 'bday'
                }, {
                    label: 'Current networth',
                    type: 'number',
                    placeholder: '100000',
                    step: '25000'
                }, {
                    label: 'Super balance',
                    type: 'number',
                    placeholder: '100000',
                    step: '25000'
                }, {
                    label: 'Super employer guarantee',
                    step: '0.005'
                }, {
                    label: 'Growth rate',
                    type: 'number',
                    step: '0.005'
                }]
            },
            'Savings rate': {
                fields: [{
                    label: 'Income before tax',
                    type: 'number',
                    placeholder: '80000',
                    step: '5000'
                }, {
                    label: 'Income after tax',
                    type: 'number',
                    placeholder: '65000',
                    step: '5000'
                }, {
                    label: 'Annual savings',
                    type: 'number',
                }]
            },
            'Goals': {
                fields: [{
                    label: 'Retirement age',
                    type: 'number'
                }, {
                    label: 'Desired income',
                    type: 'number'
                }]
            }
        });

     return class extends Component{
        render(){
            return (
                <Component steps={steps} {...this.props}></Component>
            );
        }
    } 
}


