import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {formatMoney, formatDate, formatPercentage} from 'helpers/formating'
import {FireTable} from 'components';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'views/Assets/actions';

import {
  Avatar,
  Typography,
} from '@material-ui/core';

import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const AssetsTable = props => {
  const { className, assets, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {profile} = useSelector(state => state);

  const handleDelete = (selectedAssets) => {
    dispatch(actions.deleteAssets(profile, selectedAssets));
  }

  return (
    <FireTable className={className}
      rows={assets}
      columns={["Name", "Value", "Valued At", "Growth Rate", "Income", "Expenses", "Contributions", "Frequency"]}
      renderRow={(asset) => 
        [     
          <div className={classes.nameContainer}>
            <Avatar
              className={classes.avatar}
              src={asset.avatarUrl}
            >
              {getInitials(asset.name)}
            </Avatar>
            <Typography variant="body1">{asset.name}</Typography>
          </div>,
          formatMoney(asset.value),
          formatDate(asset.valuedAt),
          formatPercentage(asset.annualGrowthRatePercentage),
          formatPercentage(asset.annualIncomePercentage),
          formatPercentage(asset.annualExpensePercentage),
          formatMoney(asset.contributions.amount),
          asset.contributions.frequency
        ]}
      onDelete={handleDelete}
    />);
};

AssetsTable.propTypes = {
  className: PropTypes.string,
  assets: PropTypes.array.isRequired
};

export default AssetsTable;
