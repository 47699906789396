import { API } from "aws-amplify";
import * as rootActions  from 'rootActions'

/*
 * action types
 */

export const SELECT_PROFILE = 'SELECT_PROFILE'
export const PROFILE_CREATED = "PROFILE_CREATED"

export function selectProfile(profile) {
  return { type: SELECT_PROFILE, profile }
}

export function profileCreated(profile) {
    return { type: PROFILE_CREATED, profile }
}

export function fetchProfile(profile) {
  return async dispatch => {
    dispatch(rootActions.loading());
    try {
      const profileResponse = await API.get("API", profile.links.self.href);
  
      dispatch(selectProfile(profileResponse));
  
    } catch (err) {
      console.log(err);
    }

    dispatch(rootActions.loaded());
  }
}

export function createProfile(profilesEndpoint, profile, people) {
  return async dispatch => {

    dispatch(rootActions.loading());

    try {
      const profileResponse = await API.post("API", profilesEndpoint, { body: profile } );

      const peopleResponse = await API.post("API", profileResponse.links.people.href, { body: people, response: true} );
  
      dispatch(profileCreated(profileResponse));
  
    } catch (err) {
      console.log(err);
    }

    dispatch(rootActions.loaded());
  }
}