import React from 'react';
import { SignOut } from 'aws-amplify-react'
import { Button, Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputIcon from '@material-ui/icons/Input';
import * as actions from '../actions';
import { compose } from 'redux'
import { connect } from 'react-redux'

const styles = (theme => ({
  icon: {
    margin: "0 8px"
  }
}));

class SignOutButton extends SignOut {
  constructor(props) {
    super(props);
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  handleSignOut = (e) => {
    this.props.signOut();
    this.signOut(e);
  }

  render() {

    const { classes } = this.props;

    return (
        <Button {...this.props}
          onClick={this.handleSignOut}>
        <InputIcon className={classes.icon} />
        
        <Hidden mdDown>
            Sign out
        </Hidden>
      </Button>
    
    )
  };
}

const mapDispatchToProps = {
  ...actions
}

const enhance = compose(
  connect(null, mapDispatchToProps), 
  withStyles(styles),
);

export default enhance(SignOutButton);