import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import layoutReducer from 'layouts/Main/reducer';
import onboardingReducer from 'views/Onboarding/reducer';
import * as profiles from 'views/Profiles/reducer';
import * as liabilities from 'views/Liabilities/reducer';
import * as income from 'views/Income/reducer';
import * as assets from 'views/Assets/reducer';
import * as expenses from 'views/Expenses/reducer';
import * as overview from 'views/Overview/reducer';
import * as scenarios from 'views/Scenarios/reducer';
import * as people from 'views/People/reducer';
import { SELECT_PROFILE } from 'views/Profiles/actions';
import { LOADED, LOADING, API_ROOT_FETCHED } from 'rootActions';
import {mutate} from 'helpers/mutations';
import { SIGNED_OUT } from 'views/Auth/actions';

const initialState = {
  isLoading: false,
  forceRefresh: 0
};

const appReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOADED:
      return  mutate(state, {
        isLoading: false
      });
    case LOADING:
      return mutate(state, {
        isLoading: true
      });      
    case 'REDUX_STORAGE_LOAD':
        return mutate(state, { forceRefresh: true });

    case SELECT_PROFILE:
        return mutate(state, { forceRefresh: false });

    default:
      return state;
  }
}


const apiReducer = {reducer: (state = {}, action) => {
  switch(action.type) {
    case API_ROOT_FETCHED: 
      return action.root;
    default:
      return state;
  }
}, initialState: {}};

const resetState = ({reducer, initialState}) => (state, action) => {
  switch (action.type) {
  case SIGNED_OUT: {
    return initialState;
  }
  default:
      return reducer(state, action);
  }
}

export default (history) => combineReducers({
  router: connectRouter(history),
  app: appReducer,
  layout: layoutReducer,
  api: resetState(apiReducer),
  income: resetState(income),
  overview: resetState(overview),
  onboarding: onboardingReducer,
  profile: resetState(profiles),
  assets: resetState(assets),
  liabilities: resetState(liabilities),
  expenses: resetState(expenses),
  scenarios: resetState(scenarios),
  people: resetState(people),
});