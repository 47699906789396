/* eslint-disable no-script-url */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from 'layouts/Title';
import { format, parseISO } from 'date-fns'
import numeral from 'numeral'

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

const formatDate = d => format(parseISO(d), 'd/MM/yyyy');
const formatMoney = y => numeral(y).format('$0,0.00');

export default function NetworthProjection(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Networth Projection</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Period</TableCell>
            <TableCell>Cashflow</TableCell>
            <TableCell>Account Balance</TableCell>
            <TableCell>Networth</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.projection.map(row => (
            <TableRow key={row.period}>
              <TableCell>{formatDate(row.period)}</TableCell>
              <TableCell>{formatMoney(row.cashflow)}</TableCell>
              <TableCell>{formatMoney(row.accountBalance)}</TableCell>
              <TableCell>{formatMoney(row.networth)}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}