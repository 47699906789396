import React, {useState} from 'react';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import {Wizard} from '../../../components/Wizard';
import {Essentials, AboutYou, Occupation} from './Steps';
import {useSelector, useDispatch} from "react-redux";
import {createEssentials} from './actions';

const OnboardingWizard = () => {
    const [state, setState] = useState({values: {}, errors: {}});
    const dispatch = useDispatch(); 
    const accountsEndpoint = useSelector(state => state.api.links.accounts.href);
    const profilesEndpoint = useSelector(state => state.api.links.profiles.href);

    const onNext = (isDone) => {
        if (!isDone) {
            return;
        }

        const {values: {birthYear, grossIncome, totalSavings, monthlySavings}} = state;
        const essentials = {birthYear, grossIncome, totalSavings, monthlySavings};
        
        dispatch(createEssentials(accountsEndpoint, profilesEndpoint, essentials));
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
            <Wizard {...{state, setState, onNext}}>
                <Essentials label="Essentials" />
                <AboutYou label="About you" />
                <Occupation label="Occupation" isOptional />
            </Wizard>
        </MuiPickersUtilsProvider>
    );
};

export {OnboardingWizard};
