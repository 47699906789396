import numeral from 'numeral'
import { format, parseISO, isValid} from 'date-fns'

export function formatDate(d) {
    if (d === undefined) {
        return undefined;
    }
    var result = tryParse(d);

    return isValid(result) ? tryFormat(result) : d;
}

const tryFormat = (d) => tryFunc(d, (c) => format(c,'MMM yyyy'));
const tryParse = (d) => tryFunc(d, parseISO);

function tryFunc(d, func) {
    try {
        return func(d);
    } catch {
        return d;
    }
}

export function formatMoney(y) {
    if (y === undefined) {
        return undefined;
    }
    return numeral(y).format('$0,0'); 
}
export function formatPercentage(y) {
    if (y === undefined) {
        return undefined;
    }
     return `${y} %`;
}