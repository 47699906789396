import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import {useForecast} from './hooks'
import {
  Brush,
  ComposedChart,
  Bar,
  Area,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  MenuItem
} from "@material-ui/core";
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import DropdownMenu from 'components/DropdownMenu';
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CashflowToolTip from "./CashflowToolTip"
import numeral from "numeral";
import { CashflowDispatchContext, RangePresets, Projections, Views} from "../../CashflowContext";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

function formatYAxis(y) {
  return numeral(y).format("0.0a");
}

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#ffc658",
  "#8884d8",
  "#ffc658",
];

const CashflowProjection = (props) => {

  const {
    rangePreset,
    projection,
    view,
    getVal,
    getXVal,
    lineKey,
    stacks,
    areas,
    data,
  } = useForecast();
  
  const dispatch = useContext(CashflowDispatchContext);

  const handleProjectionChange = (projection) => {
    dispatch({type: 'SET_PROJECTION', projection});
  }

  const handleRangeChange = (preset) => {    
    dispatch({type: 'SET_RANGE', preset});
  }

  const handleViewChange = (view) => {
    dispatch({type: 'SET_VIEW', view});
  }

  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root)}>
      <CardHeader
        title={
          <div>
          <DropdownMenu title={`${projection} projection` }
                    onClose={() => handleProjectionChange(projection)}>
              <MenuItem onClick={() => handleProjectionChange(Projections.CASHFLOW)}>Cashflow projection</MenuItem>
              <MenuItem onClick={() => handleProjectionChange(Projections.EQUITY)}>Equity projection</MenuItem>
          </DropdownMenu>
          
          <DropdownMenu title={view}
                      onClose={() => handleViewChange(view)}>
              {Views[projection].map(view => 
                <MenuItem onClick={() => handleViewChange(view)}>{view}</MenuItem>
              )}
          </DropdownMenu>
          </div>
        }
        action={
          <div>
            <DropdownMenu title={rangePreset.replace('_', ' ')}
                      icon={<CalendarTodayOutlinedIcon/>}
                      onClose={() => handleRangeChange(rangePreset)}>
              
              <MenuItem onClick={() => handleRangeChange(RangePresets.TWELVE_MONTHS)}>12 Months</MenuItem>
              <MenuItem onClick={() => handleRangeChange(RangePresets.TEN_YEARS)}>10 Years</MenuItem>
              <MenuItem onClick={() => handleRangeChange(RangePresets.FORTY_YEARS)}>40 Years</MenuItem>
            </DropdownMenu>
          </div>
        }
      />
      <Divider />
      <CardContent className={clsx(classes.content, className)}>
        <ResponsiveContainer>
          <ComposedChart
            data={data}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 24,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis dataKey={getXVal} />
            <YAxis tickFormatter={formatYAxis}>
              <Label
                angle={270}
                position="left"
                style={{ textAnchor: "middle" }}
              >
                Value ($)
              </Label>
            </YAxis>

            <Tooltip
              cursor={{ strokeWidth: 2 }}
              wrapperStyle={{ zIndex: 1000 }}
              content={<CashflowToolTip />}
            />
            <Legend />
            <Brush dataKey={getXVal} height={30} stroke="#8884d8" />

            {stacks.map(({ key, label }, i) => (
              <Bar
                key={i}
                barSize={20}
                type="monotone"

                dataKey={(x) => getVal(x, key)}
                name={key}
                stackId="1"
                fill={COLORS[i]}
              />
            ))}
            {lineKey && (
              <Line
                type="monotone"
                dot={false}
                dataKey={(x) => getVal(x, lineKey)}
                name={lineKey}
                stroke="red"
                activeDot={false}
                hide={false}
              />)}

            {areas.map((area, i) => (
              <Area 
                type="monotone" 
                name={area}
                dataKey={(x) => getVal(x, area)}         
                fill={COLORS[i]}
                legendType="none"
              />

            ))}

            
          </ComposedChart>
        </ResponsiveContainer>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button color="primary" size="small" variant="text">
          View all <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

export default CashflowProjection;
