import React, { useState, Fragment } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { ActionsBar } from 'components'; 
import { MultiSelectTable } from 'components';
import {
  Card,
  CardActions,
  CardContent,
  TablePagination
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignRows: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const FireTable = props => {
  const { className, rows, columns, renderRow, onDelete, ...rest } = props;

  const classes = useStyles();

  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };
  
  const handleDelete = () => {
    onDelete(selectedRows);
  }

  return (
    <Fragment>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
                <MultiSelectTable 
                rows={rows}
                columns={columns}
                rowsPerPage={rowsPerPage}
                selectedRows={selectedRows}
                onRowsSelected={setSelectedRows}
                renderRow={renderRow}/>                  
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={rows.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <ActionsBar 
        count={selectedRows.length}
        open={selectedRows.length > 0} 
        onDelete={handleDelete}
      />
    </Fragment>
  );
};

FireTable.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  renderRow: PropTypes.func.isRequired
};

export default FireTable;
