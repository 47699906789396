import { API } from "aws-amplify";
import * as rootActions  from 'rootActions';
import {profileCreated} from '../../Profiles/actions';
import {expenseCreated} from '../../Expenses/actions';

const yearlyIncomeFrequency = 4;
const defaultProfile = {name: 'Default profile'};

const getMonthlyExpense = (yearlyGrossIncome, yearlyTax, monthlySavings) => {
  const yearlyNetIncome = yearlyGrossIncome - yearlyTax;
  const monthlyNetIncome = yearlyNetIncome / 12;
  return monthlyNetIncome - monthlySavings;
};

export function createEssentials(accountsEndpoint, profilesEndpoint, essentials, onSuccess) {
  return async dispatch => {

    dispatch(rootActions.loading());

    const {birthYear, grossIncome, totalSavings, monthlySavings} = essentials;;

    try {
      // ensure there's an account
      const accountResponse = await API.post("API", accountsEndpoint);

      const profileResponse = await API.post("API", profilesEndpoint, { body: defaultProfile } );
      const links = profileResponse.links;

      const people = [{birthYear, isPrimary: true}];
      const peopleResponse = await API.post("API", links.people.href, { body: people, response: true} );

      const income = {name: 'Income', amount: grossIncome, frequency: yearlyIncomeFrequency};
      const incomeResponse = await API.post("API", links.incomes.href, { body: income, response: true} );
      const {data: {tax}} = incomeResponse;

      const expenseAmount = getMonthlyExpense(grossIncome, tax, monthlySavings);
      const expense = {name: 'Expenses', frequency: 'Monthly', amount: expenseAmount};
      const expenseResponse = await API.post("API", links.expenses.href, { body: expense } );

      const cash = {name: 'Savings', accountBalance: totalSavings, cashRate: 1, valueAt: new Date()};      
      const cashResponse = await API.post("API", links.cashAccounts.href, { body: cash } );

      dispatch(profileCreated(profileResponse));
      dispatch(expenseCreated(expenseResponse));

      if (onSuccess) {
        onSuccess();
      }

    } catch (err) {
      console.log(err);
      throw err;
    }

    dispatch(rootActions.loaded());
  }
}