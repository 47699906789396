import React, { Component, Fragment } from 'react';
import { Button, TextField, Box } from '@material-ui/core';
import { ConfirmSignUp } from 'aws-amplify-react'
import AuthComponent from './AuthComponent'
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link'


class FireConfirmationSignUp extends ConfirmSignUp {
  constructor(props) {
    super(props);
    this.gotoSignIn = this.gotoSignIn.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  state = {
    loading: false
  }

  confirm() {
    this.setState({loading : true});
    super.confirm();
    this.setState({loading : false});
  }
  
  gotoSignIn() {
    // to switch the authState to 'signIn'
    this.props.onStateChange('signIn',{});
  }

  showComponent(theme) {

    return (
      <AuthComponent title="Confirm code" loading={this.state.loading} {...this.props} 
        component={({ classes }) =>
        <Fragment>
          <Box justifyContent="center">
            <p>We've sent a confirmation code to your email address. </p>
            <p>Please verify your account.</p>            
          </Box>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="code"
                label="Confirmation Code"
                type="tel"
                id="code"
                onChange={this.handleInputChange}
                />  
            <Box display="flex" justifyContent="center">
              <Button
                  size="large"
                  margin="normal"
                  variant="contained"
                  color="primary"
                  className={classes.verify}
                  onClick={this.confirm}>
                  Verify
              </Button>
              <Button
                size="large"
                margin="normal"
                variant="contained"
                className={classes.resend}
                onClick={this.resend}>
                Resend
              </Button>
            </Box>
            <Grid container justify="flex-start">
              <Grid item>
                <Link href="#" onClick={this.gotoSignIn} variant="body2">
                  Go back to sign in?
              </Link>
              </Grid>
            </Grid> 
          </Fragment>  
        }/>
    );  
  }

};

export default FireConfirmationSignUp;