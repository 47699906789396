import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import IncomeList from './List';
import {API} from 'aws-amplify';
import {fetchIncomeList}  from 'views/Income/actions';
import { styles } from 'dashboardStyles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(styles);

const Income = () => {
    const {profile, app, income} = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        if (profile.links === undefined)
          return;

        dispatch(fetchIncomeList(profile));
    }, [profile.id]);

    const Loading = () => (
      <div>    
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
      </div>);

     return <Grid item xs={12}>
      {app.isLoading ? <Loading/> : <IncomeList income={income.list} />}
     </Grid>;
}
export default Income