import React, {useRef} from 'react';
import TextField from '@material-ui/core/TextField';
import { 
    Grid,
    Typography,
} from '@material-ui/core';
import {wizardStep} from '../../../../components/Wizard';
import {MoneyInput, PercentageInput} from "../../../../components/FinancialFields";
import {useControlProps} from '../useControlProps';
import {useStyles} from '../useStyles';

const yearsAgo = (n) => (new Date()).getFullYear() - n;

const fields = Object.freeze({
    birthYear: 'birthYear',
    grossIncome: 'grossIncome',
    totalSavings: 'totalSavings',
    monthlySavings: 'monthlySavings',
});

const initialState = Object.freeze({
    birthYear: yearsAgo(35),
    totalSavings: '',
    grossIncome: '',
    monthlySavings: '',
});

const Essentials = ({state, setState, canProceed}) => {
    const classes = useStyles();
    const formRef = useRef(null);
    const {controlProps} = useControlProps({state, setState, initialState, formRef, canProceed});

    return (
        <Grid container spacing={1}>
            <Grid item xs={3}>

                <form ref={formRef} noValidate autoComplete="off">
                    <TextField 
                        {...controlProps(fields.birthYear)}
                        label="What year were you born?"
                        type="number"
                    />
                    <MoneyInput
                        {...controlProps(fields.totalSavings)}
                        label="How much have you saved so far?"
                    />
                    <MoneyInput
                        {...controlProps(fields.grossIncome)}
                        label="What is your annual gross income?"
                    />
                    <MoneyInput
                        {...controlProps(fields.monthlySavings)}
                        label="How much do you save each month?"
                    />
                </form>

            </Grid>
            <Grid item xs={9}>

                <div className={classes.main}>
                    <Typography variant="h1" component="h2" gutterBottom align="center">
                        $140,000
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom align="center">
                        Net cashflow
                    </Typography>
                </div> 

            </Grid>
        </Grid>
    );
};

const _ = wizardStep(Essentials)
export {_ as Essentials};
