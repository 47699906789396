import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { Redirect } from "react-router-dom";
import {  compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import * as actions from './actions'

import withFlow from './flow'

const styles = (theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
  instructions: {
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
    flexDirection: 'column',
    alignItems: 'center',
  }
}));

class OnboardingFlow extends React.Component {

  state = {
    ...this.props.input
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.buildForm = this.buildForm.bind(this);
    this.getStepContent = this.getStepContent.bind(this);
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value  });
  };

  toCamelCase = str =>
    str
      .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
      .replace(/\s/g, '')
      .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });

  buildForm(schema) {
    return <Container component="main" maxWidth="xs">
      {schema.fields.map((fields, i) => {
        const key = fields.label.toLowerCase().replace(/\s+/g, '-');
        const name = this.toCamelCase(fields.label);
        return <TextField
          key={key}
          id={key}
          name={key}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          autoFocus={i == 0}
          {...fields}
          value={this.state[name]}
          inputProps={fields}

          onChange={this.handleChange(name)}
        />
      })}
    </Container>
  }

  getStepContent(step) {
    const {steps} = this.props;
    let [, definition] = steps[step];
    return this.buildForm(definition);
  }

  render() {
    const { classes, steps, activeStep, input } = this.props;

    return (
     (this.props.complete ? 
      <Redirect to="/forecast"></Redirect> :
      <div className={classes.root} >
        <Stepper activeStep={activeStep}>
          {steps.map(([label]) => {
            return (
              <Step key={label}>
                <StepLabel >{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>  
            <div>{this.getStepContent(activeStep)}</div>
            <div  style={{ flex: 1 }} >
              <Button  disabled={activeStep === 0} onClick={() => this.props.backStep(this.state)} className={classes.button}>
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => activeStep === steps.length - 1 ? this.props.finish(this.state) : this.props.nextStep(this.state)}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div> 
        </div>
      </div>
    ));
  }
}

const mapStateToProps = state => {
  return Object.assign({}, state.onboarding);
}

const mapDispatchToProps = {
  ...actions,
  push
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps), 
  withStyles(styles),
  withFlow
);
export default enhance(OnboardingFlow);
