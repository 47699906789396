import React, {Fragment,useState, createRef, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { API } from "aws-amplify";
import Highlight from "react-highlight";
import Loading from './Loading'
import {
  Link,
  Breadcrumbs,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import UrlBar from "./UrlBar";

const useStyles = makeStyles(theme => ({
  root: {
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  input: {
    flex: 1,
  }
}));

const ApiTester = props =>  {
    const [url, setUrl] = useState("/");
    const [resp, setResp] = useState("");
    const [isLoading, setLoading] = useState(false);

    const codeRef = createRef();

    useEffect(() => {
      send({url: '/', method: 'GET'});
    }, [])
    
    useEffect(() => {

      const handleLinkClick = async e => {
        const newUrl = e.target.innerText.replace(/"/g, '');
        setUrl(newUrl); 
        await send({url: newUrl, method: 'GET'});
      };
  
      const links = document.evaluate("//span[contains(., 'href')]/following-sibling::span[1]", codeRef.current, null, XPathResult.ANY_TYPE, null); 
      
      let currentLink = links.iterateNext();

      while(currentLink !== null){
        currentLink.onclick = handleLinkClick;
        currentLink.style.cursor = "pointer";
        currentLink.style.textDecoration="underline";
        currentLink = links.iterateNext();
      }
    });

    const send = async ({url, method}) => {

      setLoading(true);

      let returnAxios = { 
        response: true  
      }

      var sendRequest = {
        "GET": (url) => API.get('API', url, returnAxios),
        "POST": (url) => API.post('API', url, returnAxios),
        "PUT": (url) => API.put('API', url, returnAxios),
        "DELETE": (url) => API.del('API', url, returnAxios),
      }[method];
    
      await sendRequest(url)
      .then(resp => setResp(JSON.stringify(resp, null, "  ")))
      .catch(error => {
          setResp(JSON.stringify(error.response, null, "  "));
      });

      setLoading(false);
    }

    const handleBreadCrumb =  (e, url) =>{
      e.preventDefault();
      setUrl(url);
      send({url, method: 'GET'});
    }
  
    return(   
    <Fragment>
      <Breadcrumbs separator=">" aria-label="breadcrumb">
        {url.split('/').map((segment, i) => 
          <Link key={i} color="inherit" href={`/${segment}`} onClick={e => handleBreadCrumb(e, `/${segment}`)}>
            {`/${segment}`}
          </Link>
        )}
      </Breadcrumbs>
        <Grid item xs={12} >
          <UrlBar url={url} setUrl={setUrl} onClick={send}/>
        </Grid>

        <Grid item xs={12} >
          <Paper ref={codeRef}>
            {isLoading ? <Loading/> :
            <Highlight className="javascript">
              {resp}
            </Highlight>
            }
          </Paper>
        </Grid> 
      </Fragment>
    );
    
}
export default ApiTester