import React from "react";
import reductio from 'reductio';
import { CashflowContext, RangePresets, Projections } from "../../CashflowContext";
import { format } from "date-fns";
import crossfilter from "crossfilter2";

const formatYear = (d) => {
  return format(d, "yyyy");
};

const createStacks = (ndx, filter) => {
  const filteredNdx =  crossfilter(ndx
    .dimension((d) => d.key)
    .top(Infinity).filter(filter));

    return filteredNdx    
    .dimension((d) => d.key)
    .group().all();
};

const createAreas = (ndx, filter) => {
  var areas = filter();
  if (!areas.length) {
    return [];
  }

  return areas;  
};

const accumulativeGroupReduce = (dimension, options) => {
  return dimension.group().reduce(
    function (p, v) {
      if (options.line(v)) {
        p[options.lineKey] = Math.abs(v.amount) + (p[options.lineKey] || 0);
        return p;
      }

      if (p[v.key] === undefined) {
        p[v.key] = 0;
      }

      p[v.key] += Math.abs(v.amount);
      return p;
    },
    function (p, v) {
      if (options.line(v)) {
        p[options.lineKey] = Math.abs(v.amount) - (p[options.lineKey] || 0);
        return p;
      }

      if (p[v.key] === undefined) {
        p[v.key] = 0;
      }

      p[v.key] -= Math.abs(v.amount);
      return p;
    },
    function () {
      return {};
    }
  );
};

const groupReduceSum = (dimension, options) => {
  return dimension.group().reduceSum((x) => x.amount)
};

const groupReduceMax = (dimension, options) => {
  const reducer = reductio().max(d => d.amount);
  var group = dimension.group();
  return reducer(group);
};

const maxValueGroupReduce = (dimension, options) => {
  return dimension.group().reduce(
    function (p, v) {
      if (options.line(v)) {
        p[options.lineKey] = Math.max(Math.abs(v.amount), (p[options.lineKey] || 0));
        return p;
      }

      if (p[v.key] === undefined) {
        p[v.key] = 0;
      }

      p[v.key] = Math.max(p[v.key], Math.abs(v.amount));
      return p;
    },
    function (p, v) {
      if (options.line(v)) {
        p[options.lineKey] = Math.min(Math.abs(v.amount), (p[options.lineKey] || 0));
        return p;
      }

      if (p[v.key] === undefined) {
        p[v.key] = 0;
      }

      p[v.key] = Math.min(p[v.key], Math.abs(v.amount));
      return p;
    },
    function () {
      return {};
    }
  );
};

export const RangeFiltersDimension = Object.freeze({
  [RangePresets.TWELVE_MONTHS]: (d) => d.month,
  [RangePresets.TEN_YEARS]: (d) => d.year,
  [RangePresets.FORTY_YEARS]: (d) => d.year,
});

const cashflowViews = {
  "Expenses": {
    bars: (x) => x.amount < 0,
    areas: (x) => false,
    lineKey: "Income", 
    line: v => v.amount > 0,
    dimension: v => v.year,
    reduce:  accumulativeGroupReduce
  },
  "Income": {
    bars: (x) => x.amount > 0,
    areas: (x) => false,
    lineKey: "Expenses", 
    line: v => v.amount < 0,
    dimension: v => v.year,
    reduce: accumulativeGroupReduce
  },
  "Net Cashflow": {
    bars: (x) => false,
    areas: (x) => ["Net Cashflow"],
    line: v => false,
    lineKey: false, 
    dimension: v => v.year,
    reduce: groupReduceSum
  }
}

const equityViews = {
  "Assets": {
    bars: (x) => x.amount > 0,
    areas: (x) => false,
    lineKey: "Liabilities", 
    line: v => v.amount < 0,
    dimension: v => v.year,
    reduce: maxValueGroupReduce
  },
  "Liabilities": {
    bars: (x) => x.amount < 0,
    areas: (x) => false,
    lineKey: "Assets", 
    line: v => v.amount > 0,
    dimension: v => v.year,
    reduce: maxValueGroupReduce
  },
  "Networth": {
    bars: (x) => false,
    areas: (x) => ["Networth"],
    line: v => false,
    lineKey: false, 
    dimension: v => v.year,
    reduce: groupReduceMax
  }
}

const datasource = (viewConfig, context) => {
  const { bars, areas, line, lineKey } = viewConfig;
  const ndx = context.ndx;
  const projection = context.projection;
  const view = context.view;
  const rangePreset = context.rangePreset;

  const stackedBars = createStacks(ndx, bars);
  const stackedAreas = createAreas(ndx, areas)

  const dimBy = RangeFiltersDimension[rangePreset]

  const dimension = ndx.dimension(dimBy);

  const cashflowGroups = viewConfig.reduce(dimension, {
    lineKey: lineKey,
    line: line,
  });

  const getVal = (x, key) => {    
    // todo might need to push this into the group reducers
    // as it is different depending on which reducer is used.
    if (!isNaN(x.value)) {
      return x.value;
    }

    return x.value.max || x.value[key] || 0;
  };

  const getXVal = (x) => {
    return formatYear(x.key);
  };

  const data = cashflowGroups.all();

  return {
    ndx,
    projection,
    view,
    rangePreset,
    getVal,
    getXVal,
    lineKey,
    stacks:stackedBars,
    areas:stackedAreas,
    data,
  };
};

const equitySource = (context) => {
  return datasource(equityViews[context.view], context);
}

const cashflowSource = (context) => {   
  return datasource(cashflowViews[context.view], context);
};

const useForecast = () => {
  const context = React.useContext(CashflowContext);
  const projection = context.projection;

  switch (projection) {
    case Projections.CASHFLOW:
      return cashflowSource(context)
    case Projections.EQUITY:
      return equitySource(context)
    default:
      throw new Error("no hook for projection " + projection)
  }
}

export { useForecast };
