import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { ScenarioTable } from './components';
import { Toolbar } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ScenarioList = ({income}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Toolbar title={"Scenario"} entity={"income"} to="/income/create" />
      <div className={classes.content}>
        <ScenarioTable income={income} />
      </div>
    </div>
  );
};

export default ScenarioList;
