/* eslint-disable no-script-url */

import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Title from 'layouts/Title';
import { format, parseISO } from 'date-fns'
import {  formatMoney } from 'helpers/formating';

const formatDate = d => format(parseISO(d), "io 'of' MMM',' yyyy");

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function PayOffDate(props) {
    
  const classes = useStyles();

  const lastRepayment = props.schedule[props.schedule.length - 1];
  
  if(!lastRepayment){
    return null;    
  }

  return (  
    <React.Fragment>
     <div>
        <Title>Last Payment</Title>
        <Typography component="p" variant="h4">
            {formatMoney(lastRepayment.totalPayment)}
        </Typography>
        <Typography color="textSecondary" className={classes.depositContext}>
            {'On the ' + formatDate(lastRepayment.paymentDate)}
        </Typography>
        <Typography color="textSecondary" className={classes.depositContext}>
            {'Interest paid ' + formatMoney(lastRepayment.cumulativeInterest)}
        </Typography>
      </div>
    </React.Fragment>
  );
}
