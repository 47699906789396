import { SELECT_EXPENSE, EXPENSE_CREATED, EXPENSE_DELETED, EXPENSES_FETCHED } from './actions'
import { SELECT_PROFILE } from 'views/Profiles/actions';

const initialState = {
    editor: {},
    list: []
}

function selectExpense(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState =  Object.assign({}, state, { editor: action.expense });
  return newState
}

function selectExpenseList(state = initialState, action) {
    var newState = Object.assign({}, state);
    newState =  Object.assign({}, state, { list: action.expenseList });
    return newState
}

function reducer(state = initialState, action){
    switch (action.type) {
        case SELECT_EXPENSE:
            return selectExpense(state, action);
        case EXPENSE_CREATED:
            return selectExpense(state, action);
        case EXPENSE_DELETED:
            return selectExpense(state, action);
        case EXPENSES_FETCHED:
            return selectExpenseList(state, action);
    default:
        return state;
    }
}
export {reducer, initialState};