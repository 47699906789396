import { SELECT_INCOME, INCOME_CREATED, INCOME_DELETED, INCOMES_FETCHED } from './actions'
import { SELECT_PROFILE } from 'views/Profiles/actions';

const initialState = {
    editor: {},
    list: []
}

function selectIncome(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState =  Object.assign({}, state, { editor: action.income });
  return newState
}

function selectIncomeList(state = initialState, action) {
    var newState = Object.assign({}, state);
    newState =  Object.assign({}, state, { list: action.incomeList });
    return newState
}

function reducer(state = initialState, action){
    switch (action.type) {
        case SELECT_INCOME:
            return selectIncome(state, action);
        case INCOME_CREATED:
            return selectIncome(state, action);
        case INCOME_DELETED:
            return selectIncome(state, action);
        case INCOMES_FETCHED:
            return selectIncomeList(state, action);
        case SELECT_PROFILE:
            return initialState;
    default:
        return state;
    }
}
export { reducer, initialState }