import {useEffect} from 'react';
import {displayProps, validationProps} from './inputProps';

const mergeInitialState = (state, setState, initialState) => {
    const {values} = state;
    const toMerge = Object.keys(initialState).reduce((res, key) => values[key] === undefined ? {...res, [key]: initialState[key]} : res, {});
    if (Object.keys(toMerge).length) {
        setState(state => ({...state, values: {...values, ...toMerge}}));
    }
};

const useControlProps = ({state, setState, initialState = {}, formRef, canProceed}) => {
    mergeInitialState(state, setState, initialState);

    const {values, errors} = state;

    const checkCustomValidity = () => {
        const {errors} = state;
        return !Object.keys(errors).some(key => errors[key]);
    }

    useEffect(() => {
        if (formRef.current) {
            canProceed(formRef.current.checkValidity() && checkCustomValidity());
        }
    }, [state]);

    const convert = (value, type) => type === "number" && typeof value !== "number" ? parseInt(value) : value;

    const updateState = (currentTarget) => {
        const {name, value, type, validity: {valid}} = currentTarget;

        setState(state => {
            const {values, errors} = state;

            return {
                values: {
                    ...values,
                    [name]: convert(value, type),
                },
                errors: {
                    ...errors,
                    [name]: !valid,
                }
            };
        });
    };

    const actionProps = {
        onChange: (e) => {
            updateState(e.currentTarget);
        },
    };

    const getValue = (fieldName) =>
        values[fieldName] === undefined ? initialState[fieldName] : values[fieldName];

    const controlProps = (fieldName) => ({
        ...displayProps,
        ...actionProps,
        ...validationProps,
        name: fieldName,
        value: getValue(fieldName),
        error: Boolean(errors[fieldName]),
    });

    const radioProps = (fieldName) => ({
        formControlProps: {...displayProps, ...validationProps, error: Boolean(errors[fieldName])},
        radioGroupProps: {...actionProps, name: fieldName, value: getValue(fieldName)},
        radioProps: {...validationProps},
    });
    
    const props = {controlProps, radioProps};

    return props;
};

export {useControlProps};
