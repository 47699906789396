import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {TextField, Radio, Typography, Card, CardHeader, CardContent} from "@material-ui/core/";
import clsx from "clsx";

const styles = theme => ({
    familyStatusOption : {
      border: "1px solid #eeeeee",
      display: "flex",
      padding: "16px",
      maxWidth: "560px",
      alignItems: "flex-start",
      marginTop: theme.spacing(2),
  
    },
    familyStatusSelection : {
      backgroundColor: "#fafafa"
    },
    familyStatusDetails : {
      marginLeft: theme.spacing(2),
    },
  });

const Option = ({classes, state, value, handleChange}) => {
    const selected = state.familyStatus === value;
    return (
        <div className={clsx(classes.familyStatusOption, {
            [classes.familyStatusSelection] : selected
        })}>
        <Radio
            checked={selected}
            onChange={handleChange("familyStatus")}
            value={value}
            name="familyStatus"
            inputProps={{ 'aria-label': {value} }}
            />
            <div className={classes.familyStatusDetails}>
            <Typography variant="h6" gutterBottom>{value}</Typography>
            <Typography variant="subtitle1" gutterBottom>{value}</Typography>
            </div>
        </div>
    );
}

const ProfileDetails = ({className, state, handleChange, classes}) => {
    const optionProps = {state, handleChange, classes};
    return (
        <Card className={className}>
          <CardHeader title="Profile"/>
          <CardContent>
            <TextField
                fullWidth
                variant="outlined" 
                margin="normal" 
                required 
                name="profile_name"
                label="Profile name"
                value={state.name}
                onChange={handleChange("name")}
              />   
            <Typography variant="h6"></Typography>          
            <Option value="Individual"  {...optionProps}  />
            <Option value="Couple" {...optionProps} />
            <Option value="Family" {...optionProps}  />
          </CardContent>
        </Card>
    )
}

export default withStyles(styles)(ProfileDetails);
