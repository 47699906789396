import React, { Fragment, useState, useEffect } from 'react';
import { fetchApiRoot, fetchAccount } from 'rootActions';
import { useDispatch, useSelector } from 'react-redux';
import  { Auth } from 'aws-amplify';

const ApiRootProvider = ({children }) => {

    const dispatch = useDispatch();
    const api = useSelector(({api}) => api);

    useEffect(() => {
        dispatch(fetchApiRoot());  
    }, []);

    if (!api.links)
        return <div></div>

    return children;
}

const UserInfoProvider = ({render}) => {
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        const getUserId = async () => {
            const userInfo = await Auth.currentUserInfo();
            setUserInfo(userInfo);
        }

        getUserId();
    }, []);

    if (!userInfo)
        return <div></div>

    return <Fragment>
        {render(userInfo)}
    </Fragment>;
}

export { ApiRootProvider, UserInfoProvider };
