import React, { useState } from "react";
import {useSelector, useDispatch} from 'react-redux';

import PropTypes from "prop-types";
import {Backdrop, CircularProgress,CssBaseline, Container, Grid} from '@material-ui/core';
import { makeStyles, useTheme } from "@material-ui/styles";
import cslx from 'clsx';
import { useMediaQuery } from "@material-ui/core";
import { styles } from "./styles";
import {
  Sidebar,
  Topbar,
  ProfileSwitcher,
  SpeedDials,
  Footer
} from "./components";

import {openSidebar, closeSidebar} from './actions';

const useStyles = makeStyles(styles);

const Main = props => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery('(max-width:2400px) and (min-width:1200px)');
  const smallDevices = useMediaQuery('(max-width:1200px)');

  const [sidebarOpen, isLoading] = useSelector(state => {return [state.layout.sidebarOpen, state.app.isLoading]});
  const dispatch = useDispatch();

  const [openProfileSwitcher, setOpenProfileSwitcher] = useState(false);

  const onToggleSidebar = () => {
    dispatch(sidebarOpen ? closeSidebar() : openSidebar());
  };

  const onToggleProfileSwitcher = () => {
    setOpenProfileSwitcher(prev => !prev);
  };

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <Topbar 
          onToggleSidebar={onToggleSidebar}
          onToggleProfileSwitcher={onToggleProfileSwitcher} />
      <Sidebar
        isSmallDevice={smallDevices}
        onClose={onToggleSidebar}
        open={sidebarOpen}
        variant={smallDevices ? "temporary" : "persistent"}
      />
      <ProfileSwitcher
        onClose={onToggleProfileSwitcher}
        open={openProfileSwitcher}
        variant={"temporary"}
      />
      <main className={cslx({ 
        [classes.content] : true,
        [classes.shiftContent] : sidebarOpen && isDesktop,
        [classes.unshiftContent] : !sidebarOpen && isDesktop})}
      >
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            {children}
          </Grid>
        </Container>
        <SpeedDials></SpeedDials>
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
