/* eslint-disable no-script-url */

import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Title from 'layouts/Title';
import numeral from 'numeral'
import moment from 'moment'
import { format, parseISO } from 'date-fns'

const formatYear = d => format(parseISO(d), 'yyyy');

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function NetworthBalance(props) {
  const classes = useStyles();
  
  
  return (  
    <React.Fragment>
     {props.forecast && 
     <div>
        <Title>Networth</Title>
        <Typography component="p" variant="h4">
            {numeral(props.forecast.networth).format('$0,0[.]00')}
        </Typography>
        <Typography color="textSecondary" className={classes.depositContext}>
            {'In the year  ' + formatYear(props.forecast.period)}
        </Typography>
        <div>
            <Link color="primary" href="javascript:;">
            View balance
            </Link>
        </div>
      </div>}
    </React.Fragment>
  );
}
