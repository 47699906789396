import { OPEN_SIDEBAR, CLOSE_SIDEBAR } from "./actions";
const initialState = {
    sidebarOpen: false
};

const mutate = (state, mutation) => {
    var newState = Object.assign({}, state);
    newState =  Object.assign({}, state, mutation);
    return newState;
}  

const layoutReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CLOSE_SIDEBAR:
      return mutate(state, {
        sidebarOpen: false,
      });
    case OPEN_SIDEBAR:
      return mutate(state, {
        sidebarOpen: true,
      });
    default:
      return state;
  }
};

export default layoutReducer;
