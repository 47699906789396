import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import { formatMoney } from "helpers/formating";

import DefaultTooltipContent from "recharts/lib/component/DefaultTooltipContent";

const CashflowToolTip = (props) => {
  if (!props.active || props.payload == null) {
    return null;
  }

  const rows = props.payload.filter((x) => x.value != 0);

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2}>
             {props.label}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                <div style={{backgroundColor:row.color, display:"inline-block", marginRight:"2px", width:"11px", height:"11px"}}></div> {row.name}
              </TableCell>
              <TableCell align="right">{formatMoney(row.value)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // we render the default, but with our overridden payload
  //   return <DefaultTooltipContent {...props} payload={newPayload} />;
};

export default CashflowToolTip;
