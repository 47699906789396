import React, { Component } from 'react';
import {SignIn, SignUp, ForgotPassword, ConfirmSignUp} from './components';
import { Authenticator  } from 'aws-amplify-react'
import {signOut} from './actions';

const signUpConfig = {
    header : "Create FIRE Account",
    signUpFields : [
        {
            key: "email",
            required: true
        },
        {
            key: "password",
            required: true
        }
    ]
}

export default function authenticate(Comp) {
    return class extends Component {
        constructor(props) {
            super(props);

            const signin = <SignIn override={'SignIn'} usernameAttributes="email"></SignIn>;
            const signup = <SignUp override={'SignUp'} signUpConfig={signUpConfig}></SignUp>
            const confirmSignup = <ConfirmSignUp override={'ConfirmSignUp'}></ConfirmSignUp>
            const forgotPassword = <ForgotPassword override={'ForgotPassword'}></ForgotPassword>

            this.handleAuthStateChange = this.handleAuthStateChange.bind(this);

            this.state = {
                authState: props.authState || null,
                authData: props.authData || null
            };

            this.authConfig = {
                authenticatorComponents: [signin, signup, confirmSignup, forgotPassword],
                usernameAttributes: 'email',
                signUpConfig: signUpConfig  
            }            
        }

        handleAuthStateChange(state, data) {
            this.setState({ authState: state, authData: data });
        }

        render() {
            const { authState, authData } = this.state;
            const signedIn = (authState === 'signedIn');
            if (signedIn) {
                return (
                    <React.Fragment>
                        { this.authConfig.includeGreetings ? 
                            <Authenticator
                                {...this.props}
                                theme={this.authConfig.theme}
                                federated={this.authConfig.federated || this.props.federated}
                                hideDefault={this.authConfig.authenticatorComponents && this.authConfig.authenticatorComponents.length > 0}
                                signUpConfig={this.authConfig.signUpConfig}
                                usernameAttributes={this.authConfig.usernameAttributes}
                                onStateChange={this.handleAuthStateChange}
                                children={this.authConfig.authenticatorComponents || []}
                            /> : null
                        }
                        <Comp
                            {...this.props}
                            authState={authState}
                            authData={authData}
                            onStateChange={this.handleAuthStateChange}
                        />
                    </React.Fragment>
                );
            }

            this.props.dispatch(signOut());

            return <Authenticator
                {...this.props}
                theme={this.authConfig.theme}
                federated={this.authConfig.federated || this.props.federated}
                hideDefault={this.authConfig.authenticatorComponents && this.authConfig.authenticatorComponents.length > 0}
                signUpConfig={this.authConfig.signUpConfig}
                usernameAttributes={this.authConfig.usernameAttributes}
                onStateChange={this.handleAuthStateChange}
                children={this.authConfig.authenticatorComponents || []}
            />;
        }
    };
}

