import { FINANCIAL_SUMMARY_FETCHED } from './actions'

const initialState = {
    financialSummary: {
    }
}

function setFinancialSummary(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState =  Object.assign({}, state, { financialSummary: action.financialSummary });
  return newState
}

function reducer(state = initialState, action){
    switch (action.type) {
        case FINANCIAL_SUMMARY_FETCHED:
            return setFinancialSummary(state, action);
    default:
        return state;
    }
}

export {reducer, initialState};