import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid';
import Amplify, { API } from 'aws-amplify';
import { SignUp } from 'aws-amplify-react'
import  AuthComponent from './AuthComponent'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles';


const ColourTextField = colour => withStyles({
  root: {
   '& label.Mui-focused': {
    color: colour,
   },
   '& .MuiInput-underline:after': {
    borderBottomColor: colour,
   },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: colour,
    },
    '&:hover fieldset': {
      borderColor: colour,
    },
    '&.Mui-focused fieldset': {
      borderColor: colour,
    },
  },
},
})(TextField);

const GreenTextField = ColourTextField('green');
const RedTextField = ColourTextField('red');


class FireSignUp extends SignUp {
  constructor(props) {
    super(props);
    this.signUpFields = [
      {
        key: "given_name",
        required: true
      },
      {
          key: "family_name",
          required: true
      },
      {
          label: "Email",
          key: "email",
          required: true
      },
      {
          key: "password",
          required: true
      }
  ];

    this.gotoSignIn = this.gotoSignIn.bind(this);
    this.signUp = this.signUp.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.checkCode = this.checkCode.bind(this);
    this.checkCode = _.debounce(this.checkCode, 1200);
    this.requestInvitation = this.requestInvitation.bind(this);
    this.handleInviteCode = this.handleInviteCode.bind(this);
    this.signUpForm = this.signUpForm.bind(this); 
  }

  state = {
    loading: false,
    unlocked: false,
    inviteCode: '',
    invitationValidated: false,
    inviteChecked: false,
    requestInvite: false
  }

  signUp(){
    this.setState({loading : true});
    super.signUp();
  }

  handleInputChange(e){
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [target.name]: value  });
    super.handleInputChange(e);
  }

  async handleInviteCode(e){
    this.setState({
      inviteCode: e.target.value
    });

    await this.checkCode(e.target.value);
  }

  async checkCode(value){
    if(!value){
      this.setState({
        loading: false, 
        unlocked: false, 
        invitationValidated: false, 
        inviteChecked: false});
      return;
    }
  
    this.setState({loading: true});
    let returnAxios = { 
      response: true  
    }

    const resp = await API.get("InvitationCode", "/invitations/" + value, returnAxios)
    .catch(error => {
        console.log(error.response);
        this.setState({loading: false, unlocked: false, invitationValidated: false}) 
        this.error("Invitation code not valid.")
    });

    if(resp && resp.status == 200) {
      this.setState({loading: false, unlocked: true, invitationValidated: true });
      console.log(resp);
    }

    this.setState({inviteChecked: true});
  }

  validatePassword(ev){
    if(this.inputs.password != ev.target.value)
      this.error("Passwords do not match.")
  }
  
  gotoSignIn() {
    // to switch the authState to 'signIn'
    this.props.onStateChange('signIn',{});
  }

  requestInvitation(){
    this.setState({requestInvite : true});
  }

  signUpForm(classes) {
    const InviteCodeTextField = !this.state.inviteChecked  ? TextField : (this.state.invitationValidated ? 
      GreenTextField : RedTextField);
    return ( 
      <div className={classes.form} >
        <Grid container spacing={2}>
        <Grid item xs={12} s>
            <InviteCodeTextField
              autoComplete="code"
              name="invitation-code"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="invitation_code"
              label="Invitation Code"                  
              autoFocus={this.state.invitationValidated ? undefined : true}
              value={this.state.inviteCode} 
              onChange={this.handleInviteCode}
              />
                
          <Grid item>
              <Link href="#" onClick={this.requestInvitation} variant="body2">
                Don't have an invite? Register your interest.
              </Link>
          </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="given_name"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={this.state.given_name}
              autoFocus={this.state.invitationValidated ? true : undefined}
              disabled={!this.state.invitationValidated}
              id="given_name"
              label="First Name"  
              onChange={this.handleInputChange}
              />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              name="family_name"
              value={this.state.family_name}
              margin="normal"
              required
              fullWidth
              id="family_name"
              label="Last Name"
              name="family_name"      
              disabled={!this.state.invitationValidated}            
              autoComplete="family-name"
              onChange={this.handleInputChange}
              />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              value={this.state.email}
              label="Email Address"
              name="email"
              disabled={!this.state.invitationValidated}
              autoComplete="email"
              onChange={this.handleInputChange}
              />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={this.state.password}
              disabled={!this.state.invitationValidated}
              name="password"
              label="Password"
              type="password"
              id="password"                  
              autoComplete="new-password"
              onChange={this.handleInputChange}
              />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              disabled={!this.state.invitationValidated}
              name="confirm_password"
              label="Confirm Password"
              type="password"
              id="confirm_password"
              autoComplete="new-password"
              />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="I want to receive inspiration, marketing promotions and updates via email."
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          disabled={!this.state.invitationValidated}
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={this.signUp}
        >
          Sign Up
        </Button>
        <Grid container justify="flex-end">
          <Grid item>
            <Link href="#" onClick={this.gotoSignIn} variant="body2">
              Already have an account? Sign in
          </Link>
          </Grid>
        </Grid>
    </div>
    )
  }

  showComponent(theme) {
    return (
      <AuthComponent title="Sign Up"
          loading={this.state.loading} unlocked={this.state.unlocked}{...this.props} 
          component={this.signUpForm}/>
    )
  }
};

export default FireSignUp;