import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import SendIcon from '@material-ui/icons/Send';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import HomeIcon from '@material-ui/icons/Home';
import {fetchScenarioList, selectScenario} from 'views/Scenarios/actions';


import { Profile, SidebarNav, UpgradePlan } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, isSmallDevice, ...rest } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const {profile, scenarios} = useSelector(state => state);

  const createScenario = {
    title: 'Create +',
    href: '/scenarios/create'
  };

  const scenarioMenu = [...scenarios.list.map(x => ({
    title: x.title, 
    href: `/scenarios${x.links.self.href}`,
    onSelect: () => dispatch(selectScenario(x))
  })), createScenario]

  useEffect(() => {
    if(profile.links === undefined)
    {
        return;
    }
    
    dispatch(fetchScenarioList(profile));
  }, [profile.links]);

  const pages = [
    {
      title: 'Overview',
      href: '/',
      icon: <HomeIcon />
    },
    {
      title: 'Scenarios',
      icon: <DashboardIcon />,
      subMenu: scenarioMenu
    },
    {
      title: 'Income',
      href: '/income',
      icon: <PeopleIcon />
    },
    {
      title: 'Expenses',
      href: '/expenses',
      icon: <ShoppingBasketIcon />
    },
    {
      title: 'Assets',
      href: '/assets',
      icon: <LockOpenIcon />
    },
    {
      title: 'Liabilities',
      href: '/liabilities',
      icon: <TextFieldsIcon />
    },
    {
      title: 'API',
      href: '/api-tester/test',
      icon: <SendIcon/>
    },
    {
      title: 'Account',
      href: '/account',
      icon: <AccountBoxIcon />
    },
    {
      title: 'Settings',
      href: '/settings',
      icon: <SettingsIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          onClose={onClose}
          isSmallDevice={isSmallDevice}
          className={classes.nav}
          pages={pages}
        />
        <UpgradePlan />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
