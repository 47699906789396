import React, {useRef} from 'react';
import TextField from '@material-ui/core/TextField';
import { 
    FormControl,
    FormControlLabel, 
    FormLabel, 
    RadioGroup, 
    Radio, 
    Grid,
    Typography,
} from '@material-ui/core';
import {wizardStep} from '../../../../components/Wizard';
import {DatePicker} from '../../../../components/DatePicker';
import {MoneyInput, PercentageInput} from "../../../../components/FinancialFields";
import {useControlProps} from '../useControlProps';
import {useStyles} from '../useStyles';

const fields = Object.freeze({
    name: 'name',
    birthDate: 'birthDate',
    grossIncome: 'grossIncome',
    status: 'status',
    spouseBirthDate: 'spouseBirthDate',
    spouseGrossIncome: 'spouseGrossIncome',
});

const initialState = Object.freeze({
    name: '',
    birthDate: Date(),
    grossIncome: '',
    status: '',
    spouseBirthDate: Date(),
    spouseGrossIncome: '',
});

const AboutYou = ({state, setState, canProceed}) => {
    const classes = useStyles();
    const formRef = useRef(null);
    const {controlProps, radioProps} = useControlProps({state, setState, initialState, formRef, canProceed});

    const statusProps = radioProps(fields.status);
    const {values} = state;

    return (
        <Grid container spacing={1}>
            <Grid item xs={3}>

                <form ref={formRef} noValidate autoComplete="off">
                    <TextField 
                        {...controlProps(fields.name)}
                        label="Name"
                    />
                    <DatePicker
                        {...controlProps(fields.birthDate)}
                        inputVariant="outlined"
                        label="Birth date"
                    />
                    <MoneyInput
                        {...controlProps(fields.grossIncome)}
                        label="Gross income"
                    />
                    <FormControl {...statusProps.formControlProps} component="fieldset">
                        <FormLabel component="legend">Status</FormLabel>
                        <RadioGroup {...statusProps.radioGroupProps} row={true}>
                            <FormControlLabel value="single" control={<Radio {...statusProps.radioProps} />} label="Single" />
                            <FormControlLabel value="couple" control={<Radio {...statusProps.radioProps} />} label="Couple" />
                        </RadioGroup>
                        </FormControl>
                    <DatePicker
                        {...controlProps(fields.spouseBirthDate)}
                        inputVariant="outlined"
                        label="Spouse birth date"
                        disabled={values[fields.status] !== 'couple'}
                    />
                    <MoneyInput
                        {...controlProps(fields.spouseGrossIncome)}
                        label="Spouse gross income"
                        disabled={values[fields.status] !== 'couple'}
                    />
                </form>

            </Grid>
            <Grid item xs={9}>

                <div className={classes.main}>
                    <Typography variant="h1" component="h2" gutterBottom align="center">
                        $140,000
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom align="center">
                        Net cashflow
                    </Typography>
                </div> 

            </Grid>
        </Grid>
    );
};

const _ = wizardStep(AboutYou)
export {_ as AboutYou};
