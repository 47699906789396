import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, TextField } from "@material-ui/core/";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import FrequencySelect from "components/Frequency";
import { PercentageInput, MoneyInput } from 'components/FinancialFields';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const styles = theme => ({
  details: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200
    }
  }
});

const StyledTextField = props => (
  <TextField variant="outlined" margin="normal" required {...props} />
);

const ScenarioDetails = ({ className, classes, state, onChange }) => {
  const textProps = {variant:"outlined", margin:"normal", required: true}

  return (
    <Card className={className}>
      <CardHeader title="Details" />
      <CardContent className={classes.details}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
          <div>
            <StyledTextField
              name="title"
              label="Title"
              type="text"
              value={state["title"]}
              onChange={onChange("title")}
            />
          </div>
          <div>
            <StyledTextField
              name="retirementAge"
              label="Retirement age"
              type="number"
              value={state["retirementAge"]}
              onChange={onChange("retirementAge")}
            />
          </div>
        </MuiPickersUtilsProvider>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ScenarioDetails);
