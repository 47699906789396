import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = (theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loading: {
    margin: theme.spacing(1),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  
  verify:{
    margin: theme.spacing(0, 2, 2),
  },
  resend:{
    margin: theme.spacing(0, 0, 2, 2),
  }

}));

const AuthComponent = ({component: C, ...props}) => {
    const { classes, loading, unlocked } = props;
    

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
        { !loading ? 
          <Avatar className={classes.avatar}>
            { unlocked ? <LockOpenOutlinedIcon/> :  <LockOutlinedIcon /> }
          </Avatar>
               :
          <CircularProgress className={classes.loading} color="secondary" />
          }
          <Typography component="h1" variant="h5">
            {props.title}
          </Typography>
          <C {...props}></C>
        </div>
      </Container>
    )
}

export default withStyles(styles)(AuthComponent);