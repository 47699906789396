import {Auth} from 'aws-amplify';

const coreheaders = async (link) => ({ 
    'Authorization' : `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    'Content-Type': 'application/json'
   }
);

const emptyInit = {
  headers : {}
}

export const init = async (link, init = emptyInit) => {
  const headers = Object.assign(await coreheaders(link), init.headers);
  const body = init.body ? JSON.stringify(init.body) : null;
  return Object.assign(init, { headers }, { body });
};

export const tryFetch = async (link) => {
    try {
      return await fetch(process.env.REACT_APP_API_BASE_URL + link.href, await init(link))
      .then(async res => res.ok ? await res.json() : null);
    }
    catch (err) {
      console.log(`failed to fetch ${link.href}: ${err}`)
    }
}

export const tryFetchList = async (link) => {
    const links = await tryFetch(link)
    return (await Promise.all(links.list.map(tryFetch))).filter(x => x != null);
}

export const  fetchList = (resource, linkType, actionType) => {
  return async dispatch => {
    const link = resource.links[linkType];
    if (link === undefined) {
      throw new Error(`link type ${linkType} does not exist on ${JSON.stringify(resource)}`)
    }
    const list = await tryFetchList(resource.links[linkType]);

    dispatch({type: actionType, list});
  }
}
