import { SELECT_ASSET, ASSET_CREATED, ASSET_DELETED, ASSETS_FETCHED } from './actions'

const initialState = {
    editor: {},
    list: []
}

function selectAsset(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState =  Object.assign({}, state, { editor: action.asset });
  return newState
}

function selectAssetsList(state = initialState, action) {
    var newState = Object.assign({}, state);
    newState =  Object.assign({}, state, { list: action.assets });
    return newState
}

function reducer(state = initialState, action){
    switch (action.type) {
        case SELECT_ASSET:
            return selectAsset(state, action);
        case ASSET_CREATED:
            return selectAsset(state, action);
        case ASSET_DELETED:
            return selectAsset(state, action);
        case ASSETS_FETCHED:
            return selectAssetsList(state, action);
    default:
        return state;
    }
}
export {reducer, initialState};