import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, TextField } from "@material-ui/core/";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import FrequencySelect from "components/Frequency";
import { PercentageInput, MoneyInput } from 'components/FinancialFields';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const styles = theme => ({
  details: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200
    }
  }
});

const StyledTextField = props => (
  <TextField variant="outlined" margin="normal" required {...props} />
);

const IncomeDetails = ({ className, classes, state, onChange }) => {
  const textProps = {variant:"outlined", margin:"normal", required: true}

  return (
    <Card className={className}>
      <CardHeader title="Details" />
      <CardContent className={classes.details}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
          <div>
            <StyledTextField
              name="name"
              label="Name"
              type="text"
              value={state["name"]}
              onChange={onChange("name")}
            />

            <KeyboardDatePicker
              {...textProps}
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Income begins"
              value={state["startsFromUtc"]}
              onChange={onChange("startsFromUtc")}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
            <KeyboardDatePicker
              {...textProps}
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Income ends"
              value={state["endAtUtc"]}
              onChange={onChange("endAtUtc")}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
            <MoneyInput
              {...textProps}
              name="value"
              label="Amount"
              value={state["amount"]}
              onChange={onChange("amount")}
            />
            <FrequencySelect
              value={state.frequency}
              onChange={onChange("frequency")}
            />
            <PercentageInput
              {...textProps}
              id="income"
              name="income"
              label="Annual income growth"
              value={state["annualGrowthRatePercentage"]}
              onChange={onChange("annualGrowthRatePercentage")}
            />
            <PercentageInput
              {...textProps}
              id="expenses"
              fullWidth
              name="Annual expense percentage"
              label="Annual expense percentage"
              value={state["annualExpensePercentage"]}
              onChange={onChange("annualExpensePercentage")}
            />
          </div>
        </MuiPickersUtilsProvider>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(IncomeDetails);
