/*
 * action types
 */

export const NEXT = 'NEXT'
export const BACK = 'BACK'

export const FINISH = 'FINISH'
export const RESET = 'RESET'
/*
 * action creators
 */

export function nextStep(input) {
  return { type: NEXT, input }
}

export function backStep(input) {
  return { type: BACK, input }
}

export function finish(input) {
    return { type: FINISH, input }
}

export function resetSteps() {
  return { type: RESET }
}