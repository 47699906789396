import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

const MultiSelectTable = (props) => {
    const { 
      rows, 
      columns, 
      rowsPerPage, 
      renderRow, 
      selectedRows, 
      onRowsSelected = (item) => {}, 
      onItemAdded = (item) => {}, 
      onItemRemoved = (item) => {},
       ...rest } = props;

  
    const handleSelectAll = event => {
      const { rows } = props;
  
      let selected;
  
      if (event.target.checked) {
        selected = rows;
      } else {
        selected = [];
      }
      
      onRowsSelected(selected);
    };    
  
    const handleSelectOne = (event, item) => {
      const selectedIndex = selectedRows.map(x => x.links.self.href).indexOf(item.links.self.href);
      let newSelectedRows = [];
  
      if (selectedIndex === -1) {
        onItemAdded(item);
        newSelectedRows = newSelectedRows.concat(selectedRows, item);
      } 
       else if (selectedIndex === 0) {
        newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelectedRows = newSelectedRows.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }

      if (selectedIndex >= 0){
        onItemRemoved(item);
      }

      onRowsSelected(newSelectedRows);
    };
    
    const isSelected = (row) => {
      return selectedRows.map(x => x.links.self.href).indexOf(row.links.self.href) > -1;
    }
  
    return <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.length === rows.length}
                        color="primary"
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < rows.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    {columns.map(name => <TableCell>{name}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(0, rowsPerPage || rows.length).map(row => (
                    <TableRow
                      hover
                      key={row.id}
                      selected={isSelected(row)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected(row)}
                          color="primary"
                          onChange={event => handleSelectOne(event, row)}
                          value="true"
                        />
                      </TableCell>
                      {renderRow(row).map(col => <TableCell>{col}</TableCell>)}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
}


MultiSelectTable.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    renderRow: PropTypes.func.isRequired,
    setSelectedRows: PropTypes.func.isRequired,
};
  
  export default MultiSelectTable;
  