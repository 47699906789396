/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { Fragment, useState, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';

import Collapse from '@material-ui/core/Collapse';
import ChevronDown from '@material-ui/icons/KeyboardArrowDown';
import ChevronUp from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  chevronSpace : {
    flexGrow: 1
  },
  nested: {
    paddingLeft: theme.spacing(5),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => {
  return <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
});

const SidebarNav = props => {
  const { pages, className, isSmallDevice, onClose, ...rest } = props;

  const classes = useStyles();
  const [openMenus, setOpenMenus] = useState([]);

  const isMenuOpen = (menu, openMenus) => {
    return openMenus.some(m => m.title == menu.title);
  };

  const handleMenuToggle = (menu, href) => {
    setOpenMenus(prev => {
      if (isMenuOpen(menu, prev)) {
        return [...prev.filter(m => m.title != menu.title)]
      }
      return [menu, ...prev]
    })

    
    if (href !== undefined && isSmallDevice) {
      onClose();
      return false;
    }
  };

  const handleMenuClick = (item) => {
    if (item.onSelect)
      item.onSelect();

    if (isSmallDevice)
      onClose();
  }

  const linkProps = (page) => {
    return page.href ? {component:CustomRouterLink, to:page.href} : {};
  }

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {pages.map(page => (
        <Fragment>
          <ListItem
            className={classes.item}
            disableGutters
            key={page.title}
          >
            <Button
              activeClassName={classes.active}
              className={classes.button}
              onClick={() => handleMenuToggle(page, page.href)}
              {...linkProps(page)}
            >
              <div className={classes.icon}>{page.icon}</div>
                {page.title}
              <div className={classes.chevronSpace} />
              {page.subMenu && (
                (isMenuOpen(page, openMenus) ? <ChevronUp/> : <ChevronDown/>)
              )}
            </Button>
      
          </ListItem>
          {page.subMenu && (
            <Collapse in={isMenuOpen(page, openMenus)} timeout="auto" unmountOnExit>
              <List component="div" >
                  {isMenuOpen(page, openMenus) &&
                      page.subMenu.map((item, i) => {
                          return (
                          <ListItem 
                            className={classes.nested}
                            key={i}
                            component={CustomRouterLink}
                            onClick={() => handleMenuClick(item)}
                            to={item.href} >
                              {item.title}
                          </ListItem>
                          )
                      })
                  }
              </List>
          </Collapse>
        )}
      </Fragment>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
