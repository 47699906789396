import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { MultiSelectTable } from "components";
import { formatMoney, formatDate } from "helpers/formating";
import { getInitials } from "helpers";
import LiabilityForm from "./LIabilityForm";
import EditLink from "../Editing/EditLink";
import {updateLiability} from '../../actions';

import { Avatar, Typography } from "@material-ui/core";
import {
  addLiability,
  removeLiability,
} from "views/Scenarios/components/Dashboard/actions";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 800,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const statusColors = {
  delivered: "success",
  pending: "info",
  refunded: "danger",
};

const ScenarioLiabilities = (props) => {
  const {
    scenario,
    scenarioLiabilities,
    profileLiabilities,
    profile,
  } = useSelector((state) => ({
    scenario: state.scenarios.editor,
    scenarioLiabilities: state.scenarios.scenarioLiabilities,
    profileLiabilities: state.scenarios.profileLiabilities,
    profile: state.profile,
  }));

  const uris = new Set(scenarioLiabilities.map((x) => x.links.liability.href));
  const profileOnlyLiabilities = profileLiabilities.filter(
    (x) => !uris.has(x.links.self.href)
  );

  const classes = useStyles();

  const dispatch = useDispatch();

  const [openForm, setOpenForm] = useState(false);
  const [editingItem, setEditing] = useState(undefined);

  const handleEdit = (item) => {
    setEditing(item);
    setOpenForm(true);
  };

  const handleSave = (item) => {
    dispatch(updateLiability(profile, scenario, item));
    setOpenForm(false);
  };

  const handleChange = (item) => {
    setEditing(item);
  };

  return (
    <Fragment>
      <MultiSelectTable
        rows={[...scenarioLiabilities, ...profileOnlyLiabilities]}
        columns={["Name", "Value", "Acquire", "Discharge", "Actions"]}
        renderRow={(item) => [
          <div className={classes.nameContainer}>
            <Avatar className={classes.avatar} src={item.avatarUrl}>
              {getInitials(item.name)}
            </Avatar>
            <Typography variant="body1">{item.name}</Typography>
          </div>,
          formatMoney(item.value),
          formatDate(item.startsFromUtc),
          formatDate(item.endsAtUtc),
          <EditLink onClick={() => handleEdit(item)} />,
        ]}
        selectedRows={scenarioLiabilities}
        onItemAdded={(liability) =>
          dispatch(addLiability(profile, scenario, liability))
        }
        onItemRemoved={(liability) =>
          dispatch(removeLiability(profile, scenario, liability))
        }
      />
      <LiabilityForm
        open={openForm}
        onClose={() => setOpenForm(false)}
        onSave={handleSave}
        state={editingItem}
        onChange={handleChange}
      />
    </Fragment>
  );
};

export default ScenarioLiabilities;
