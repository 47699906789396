import { API } from "aws-amplify";
import * as rootActions  from 'rootActions'
import { push } from 'connected-react-router'
import { tryFetchList } from "helpers/api";

/*
 * action types
 */

export const SELECT_INCOME = 'SELECT_INCOME'
export const INCOME_CREATED = 'INCOME_CREATED'
export const INCOMES_FETCHED = 'INCOMES_FETCED';
export const INCOME_DELETED = 'INCOME_DELETED';

export function selectIncome(income) {
  return { type: SELECT_INCOME, income }
}

export function incomeCreated(income) {
    return { type: INCOME_CREATED, income }
}

export function incomesList(incomeList) {
  return {type: INCOMES_FETCHED, incomeList}
}


export function incomeDeleted(income) {
  return {type: INCOME_DELETED, income}
}

export function createIncome(profile, income) {
  return async dispatch => {

    dispatch(rootActions.loading());

    try {
      const href = profile.links.incomes.href;
      const incomeResponse = await API.post("API", href, { body: income } );
      dispatch(incomeCreated(incomeResponse));
    } catch (err) {
      console.log(err);
    }

    dispatch(rootActions.loaded());

    dispatch(push('/income'));
  }
}

export function fetchIncomeList(profile) {
  return async dispatch => {
    dispatch(rootActions.loading());
    const income = await tryFetchList(profile.links.incomes);
    dispatch(incomesList(income));
    dispatch(rootActions.loaded());
  }
}

async function deleteAction(dispatch, profile, income) {
  dispatch(rootActions.loading());

  try {
    await API.del("API", income.links.self.href);

    dispatch(incomeDeleted(income));
  } catch (err) {
    console.log(err);
  }

  dispatch(fetchIncomeList(profile));

  dispatch(rootActions.loaded());
}

export function deleteIncome(profile, income) {
  return async dispatch => {
    Promise.all(income.map(i => deleteAction(dispatch, profile, i)));
  }
}
