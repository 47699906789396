import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import * as actions from 'views/Liabilities/actions';
import {formatMoney, formatDate, formatPercentage} from 'helpers/formating'
import {FireTable} from 'components';
import {
  Button,
  Avatar,
  Typography,
} from '@material-ui/core';

import { getInitials } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LiabilitiesTable = props => {
  const { className, liabilities, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const {profile} = useSelector(state => state);
  
  const handleDelete = (selectedLiabilities) => {
    dispatch(actions.deleteLiabilities(profile, selectedLiabilities));
  }
  return (
    <FireTable className={className}
      rows={liabilities}
      columns={["Name", "Value", "Started On", "Interest Rate", "Interest Only Period", "Loan Term", "Actions"]}
      renderRow={(liability) => 
        [     
          <div className={classes.nameContainer}>
            <Avatar
              className={classes.avatar}
              src={liability.avatarUrl}
            >
              {getInitials(liability.name)}
            </Avatar>
            <Typography variant="body1">{liability.name}</Typography>
          </div>,
          formatMoney(liability.value),
          formatDate(liability.startedOnUtc),
          formatPercentage(liability.interestRate),
          `${liability.interestOnlyPeriod} yrs`,
          `${liability.loanTerm} yrs`,
          <Button 
              component={RouterLink}
              to={`liabilities/${liability.id}/view`}
              variant="outlined" 
              color="primary">View</Button>,
        ]}
      onDelete={handleDelete}
  />);
};

LiabilitiesTable.propTypes = {
  className: PropTypes.string,
  liabilities: PropTypes.array.isRequired
};

export default LiabilitiesTable;
