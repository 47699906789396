import React, {useRef} from 'react';
import TextField from '@material-ui/core/TextField';
import {MoneyInput} from "../../../../components/FinancialFields";
import {useControlProps} from '../useControlProps';

const yearsAgo = (n) => (new Date()).getFullYear() - n;

const fields = Object.freeze({
    birthYear: 'birthYear',
    grossIncome: 'grossIncome',
    totalSavings: 'totalSavings',
    monthlySavings: 'monthlySavings',
});

const initialState = Object.freeze({
    birthYear: yearsAgo(35),
    totalSavings: '',
    grossIncome: '',
    monthlySavings: '',
});

const Questionnaire = ({state, setState, canProceed}) => {
    const formRef = useRef(null);
    const {controlProps} = useControlProps({state, setState, initialState, formRef, canProceed});

    return (
        <form ref={formRef} noValidate autoComplete="off">
            <TextField 
                {...controlProps(fields.birthYear)}
                label="What year were you born?"
                type="number"
            />
            <MoneyInput
                {...controlProps(fields.totalSavings)}
                label="How much have you saved so far?"
            />
            <MoneyInput
                {...controlProps(fields.grossIncome)}
                label="What is your annual gross income?"
            />
            <MoneyInput
                {...controlProps(fields.monthlySavings)}
                label="How much do you save each month?"
            />
        </form>
    );
};

export {Questionnaire};
