import React, {useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Grid} from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {OnboardingWizard} from './OnboardingWizard/OnboardingWizard';
import {Questionnaire} from './OnboardingWizard/Questionnaire/Questionnaire';
import {createEssentials} from './OnboardingWizard/actions';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        color: theme.palette.primary.contrastText,
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef((props, ref) =>
    <Slide direction="up" ref={ref} {...props} />
);

export const Welcome = ({history}) => {
    const classes = useStyles();
    const [state, setState] = useState({values: {}, errors: {}});
    const [canSave, setCanSave] = useState(false);
    const dispatch = useDispatch(); 
    const accountsEndpoint = useSelector(state => state.api.links.accounts.href);
    const profilesEndpoint = useSelector(state => state.api.links.profiles.href);

    const canProceed = (ok) => {
        if (ok !== canSave) {
            setCanSave(ok);
        }
    };

    const saveHandler = () => {
        const {values: {birthYear, grossIncome, totalSavings, monthlySavings}} = state;
        const essentials = {birthYear, grossIncome, totalSavings, monthlySavings};
        
        dispatch(createEssentials(accountsEndpoint, profilesEndpoint, essentials, () => history.push("/")));
    };

    return (
        <Dialog fullScreen open={true} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>                
                    <Typography variant="h5" className={classes.title}>
                        Welcome
                    </Typography>
                    <Button autoFocus={false} disabled={!canSave} color="inherit" onClick={saveHandler}>
                        Continue
                    </Button>
                </Toolbar>
            </AppBar>

            <Grid container justify="center" spacing={1} style={{margin: 20}}>
                <Grid item xs={3}>
                    <Questionnaire {...{state, setState, canProceed}} /> 
                </Grid>
            </Grid>
        </Dialog>
    );

    // return (
    //     <OnboardingWizard />
    // );
};
