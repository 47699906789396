import {
  SELECT_SCENARIO,
  SCENARIO_CREATED,
  SCENARIO_DELETED,
  SCENARIOS_FETCHED,
} from "./actions";

import {
SCENARIO_FORECAST_FETCHED,
SCENARIO_ASSETS_FETCHED,
SCENARIO_ASSET_ADDED,
PROFILE_ASSETS_FETCHED,
PROFILE_LIABILITIES_FETCHED,
SCENARIO_LIABILITIES_FETCHED,
PROFILE_INCOME_FETCHED,
SCENARIO_INCOME_FETCHED,
PROFILE_CASH_ACCOUNTS_FETCHED,
SCENARIO_CASH_ACCOUNTS_FETCHED,
PROFILE_EXPENSES_FETCHED,
SCENARIO_EXPENSES_FETCHED,
  
} from "./components/Dashboard/actions";

const initialState = {
  editor: {},
  list: [],
  forecast: { periods: [], timeline: [] },
  scenarioAssets: [],
  profileAssets: [],
  scenarioLiabilities: [],
  profileLiabilities: [],
  scenarioIncome: [],
  profileIncome: [],
  scenarioCashAccounts: [],
  profileCashAccounts: [],
  scenarioExpenses: [],
  profileExpenses: [],
};

function selectScenario(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState = Object.assign({}, state, { editor: action.scenario });
  return newState;
}

function selectScenarioList(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState = Object.assign({}, state, { list: action.scenarios });
  return newState;
}

function selectProfileAssets(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState = Object.assign({}, state, { profileAssets: action.list });
  return newState;
}


function scenarioAssetAdded(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState.profileAssets = newState.profileAssets.filter(x => x.links.self.href !== action.self.href)
  newState.scenarioAssets.push(action.scenarioAsset);
  return newState;
}

function scenarioAssetRemoved(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState.profileAssets = newState.profileAssets.filter(x => x.links.self.href !== action.self.href)
  newState.scenarioAssets.push(action.scenarioAsset);
  return newState;
}

function selectScenarioAssets(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState = Object.assign({}, state, {
    scenarioAssets: action.list,
  });
  return newState;
}

function selectProfileLiabilities(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState = Object.assign({}, state, { profileLiabilities: action.list });
  return newState;
}

function selectScenarioLiabilities(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState = Object.assign({}, state, {
    scenarioLiabilities: action.list,
  });
  return newState;
}

function selectProfileIncome(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState = Object.assign({}, state, { profileIncome: action.list });
  return newState;
}

function selectScenarioIncome(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState = Object.assign({}, state, {
    scenarioIncome: action.list,
  });
  return newState;
}

function selectProfileCashAccount(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState = Object.assign({}, state, { profileCashAccounts: action.list });
  return newState;
}

function selectScenarioCashAccount(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState = Object.assign({}, state, {
    scenarioCashAccounts: action.list,
  });
  return newState;
}


function selectProfileExpenses(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState = Object.assign({}, state, { profileExpenses: action.list });
  return newState;
}

function selectScenarioExpenses(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState = Object.assign({}, state, {
    scenarioExpenses: action.list,
  });
  return newState;
}

function selectForecast(state = initialState, action) {
  var newState = Object.assign({}, state);
  newState = Object.assign({}, state, { forecast: action.forecast });
  return newState;
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_SCENARIO:
      return selectScenario(state, action);
    case SCENARIO_CREATED:
      return selectScenario(state, action);
    case SCENARIO_DELETED:
      return selectScenario(state, action);
    case SCENARIOS_FETCHED:
      return selectScenarioList(state, action);
    case SCENARIO_ASSETS_FETCHED:
      return selectScenarioAssets(state, action);
    case SCENARIO_ASSET_ADDED:
      return scenarioAssetAdded(state, action);
    case PROFILE_ASSETS_FETCHED:
      return selectProfileAssets(state, action);
    case SCENARIO_CASH_ACCOUNTS_FETCHED:
      return selectScenarioCashAccount(state, action);
    case PROFILE_CASH_ACCOUNTS_FETCHED:
      return selectProfileCashAccount(state, action);
    case SCENARIO_INCOME_FETCHED:
      return selectScenarioIncome(state, action);
    case PROFILE_INCOME_FETCHED:
      return selectProfileIncome(state, action);
    case SCENARIO_EXPENSES_FETCHED:
      return selectScenarioExpenses(state, action);
    case PROFILE_EXPENSES_FETCHED:
      return selectProfileExpenses(state, action);
    case SCENARIO_LIABILITIES_FETCHED:
      return selectScenarioLiabilities(state, action);
    case PROFILE_LIABILITIES_FETCHED:
      return selectProfileLiabilities(state, action);
    case SCENARIO_FORECAST_FETCHED:
      return selectForecast(state, action);

    default:
      return state;
  }
}
export { reducer, initialState };
